import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  LinearProgress,
  Divider,
  Grid,
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider, styled } from "@material-ui/core/styles";
import { darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import Loader from "../../../components/src/Loader.web";
import { averageIon, certificateIcon, completedIcon, progressIcon, watchIcon, calenderIcon, exploreIcon, Skip, buttonIcon } from './assets'
import { EditButtonSection, EditHeading, EditDescription } from '../../customisableuserprofiles/src/CustomisableUserProfiles.web'
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { ToastContainer, toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

import DailyScheduleNotificationsController, {
  Props,
  configJSON,
} from "./DailyScheduleNotificationsController.web";

export default class DailyScheduleNotifications extends DailyScheduleNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const mode = await getStorageData('darkMode');
    if (mode === 'true') {
      this.setState({ darkTheme: true })
    }
    toast(this.Card())
  }

  Card = () => (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Remy Sharp" src="" />
          <div style={{ marginLeft: '10px' }}>
            <NameField>Ibrahim M.</NameField>
            <DesignationField>Instructor</DesignationField>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
          <TitleCourse>Yesterday at 4:54 PM</TitleCourse>
          <DetailsButton data-test-id="detailsButton" onClick={this.gotoNotification}>Details</DetailsButton>
        </div>
      </div>
      <CourseSection>
        <TitleCourse>Comment Left on the community post</TitleCourse>
        <CourseValue>Lorem ipsum dolor sit amet, consectetur adipiscing elit. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</CourseValue>
      </CourseSection>
    </div>
  )
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
        <Loader loading={this.state.loading} />
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.openSideMenu}
          open={this.state.openMenu}
          handleTheme={this.changeTheme}
          themeDark={this.state.darkTheme}
        />
        <HeadDiv style={{ paddingLeft: this.state.openMenu ? 273 : 107 }}>
          <EditButtonSection style={{ width: '100%', maxWidth: '1070px', alignSelf: 'center', padding: '30px', display: 'flex', maxHeight: '185px', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ width: '100%' }}>
                <EditHeading>Welcome Back, Ibrahim</EditHeading>
                <EditDescription>There's new courses you can explore them now</EditDescription>
              </div>
              <div style={{ marginLeft: '15px', width: '100%', display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
                <CalendarButton
                  data-test-id={"calenderButton"}
                  variant='outlined'
                  // onClick={this.openCalender}
                  style={{ width: '100%' }}
                  startIcon={<img src={calenderIcon} />}
                >
                  Calendar
                </CalendarButton>
                <ExploreButton
                  data-test-id={"exploreButton"}
                  variant='outlined'
                  // onClick={this.exploreDailySchedule}
                  style={{ width: '100%' }}
                  startIcon={<img src={exploreIcon} />}
                >
                  Explore Leaderboard
                </ExploreButton>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '30px' }}>
              <SubSection>
                <ImageIcon src={progressIcon} />
                <SubHeadingSection>
                  <SubHeading>In Progress</SubHeading>
                  <SubDescription>5 Courses</SubDescription>
                </SubHeadingSection>
              </SubSection>
              <VerticalLine orientation="vertical" variant="middle" flexItem />
              <SubSection>
                <ImageIcon src={completedIcon} />
                <SubHeadingSection>
                  <SubHeading>In Progress</SubHeading>
                  <SubDescription>5 Courses</SubDescription>
                </SubHeadingSection>
              </SubSection>
              <VerticalLine orientation="vertical" variant="middle" flexItem />
              <SubSection>
                <ImageIcon src={averageIon} />
                <SubHeadingSection>
                  <SubHeading>Avg Score</SubHeading>
                  <SubDescription>37 Points</SubDescription>
                </SubHeadingSection>
              </SubSection>
              <VerticalLine orientation="vertical" variant="middle" flexItem />
              <SubSection>
                <ImageIcon src={watchIcon} />
                <SubHeadingSection>
                  <SubHeading>Watch Time</SubHeading>
                  <SubDescription>10hr 30Min.</SubDescription>
                </SubHeadingSection>
              </SubSection>
              <VerticalLine orientation="vertical" variant="middle" flexItem />
              <SubSection>
                <ImageIcon src={certificateIcon} />
                <SubHeadingSection>
                  <SubHeading>Certificates</SubHeading>
                  <SubDescription>6 Certificates</SubDescription>
                </SubHeadingSection>
              </SubSection>
            </div>
          </EditButtonSection>
          <BodySection container spacing={2}>
            <LeftGrid item xs={7}>
              <HalfGridTitleSection>
                <HalfGridTitle>Courses</HalfGridTitle>
                <ViewAllText>View All</ViewAllText>
              </HalfGridTitleSection>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <HalfGrid>
                  <CourseImage src={Skip} alt="" />
                  <div>
                    <ProgressBar variant="determinate" value={70} />
                    <InternalSection>
                      <div>
                        <TitleCourse>Course Name</TitleCourse>
                        <CourseValue>UI/UX principles</CourseValue>
                      </div>
                      <CourseValue>70%</CourseValue>
                    </InternalSection>

                    <InternalSection style={{ marginTop: '12px' }}>
                      <div>
                        <TitleCourse>Instructor Name</TitleCourse>
                        <CourseValue>Julia Lees Maxxo</CourseValue>
                      </div>
                      <img src={buttonIcon} />
                    </InternalSection>
                  </div>
                </HalfGrid>
                <HalfGrid>
                  <CourseImage src={Skip} alt="" />
                  <div>
                    <ProgressBar variant="determinate" value={60} />
                    <InternalSection>
                      <div>
                        <TitleCourse>Course Name</TitleCourse>
                        <CourseValue>React principles</CourseValue>
                      </div>
                      <CourseValue>60%</CourseValue>
                    </InternalSection>

                    <InternalSection style={{ marginTop: '12px' }}>
                      <div>
                        <TitleCourse>Instructor Name</TitleCourse>
                        <CourseValue>Ibrahim</CourseValue>
                      </div>
                      <img src={buttonIcon} />
                    </InternalSection>
                  </div>
                </HalfGrid>
              </div>
              <FullGrid>
                <FullGridImage src={Skip} alt="skip" />
                <div style={{ width: '50%', margin: "20px" }}>
                  <InternalSection>
                    <div>
                      <TitleCourse>Course Name</TitleCourse>
                      <CourseValue>ROR principles</CourseValue>
                    </div>
                    <img src={buttonIcon} />
                  </InternalSection>
                  <InternalSection>
                    <div>
                      <TitleCourse>Course Name</TitleCourse>
                      <CourseValue>React principles</CourseValue>
                    </div>
                    <CourseValue>60%</CourseValue>
                  </InternalSection>
                  <InternalSection>
                    <div>
                      <TitleCourse>Certificate Name</TitleCourse>
                      <CourseValue>ROR principles</CourseValue>
                    </div>
                    <CourseValue>70%</CourseValue>
                  </InternalSection>
                  <InternalSection>
                    <div>
                      <TitleCourse>Course Title</TitleCourse>
                      <CourseValue>QA principles</CourseValue>
                    </div>
                    <CourseValue>80%</CourseValue>
                  </InternalSection>
                  <InternalSection>
                    <div>
                      <TitleCourse>Subject Name</TitleCourse>
                      <CourseValue>DevOps principles</CourseValue>
                    </div>
                    <CourseValue>50%</CourseValue>
                  </InternalSection>
                </div>
              </FullGrid>
            </LeftGrid>
            <RightGrid>
              <RightSections>
                <HalfGridTitleSection>
                  <HalfGridTitle>Learning Path</HalfGridTitle>
                  <ViewAllText>{configJSON.ViewAll}</ViewAllText>
                </HalfGridTitleSection>
              </RightSections>
              <RightSections>
                <HalfGridTitleSection>
                  <HalfGridTitle>Learning Path</HalfGridTitle>
                  <ViewAllText>{configJSON.ViewAll}</ViewAllText>
                </HalfGridTitleSection>
              </RightSections>
              <RightSections>
                <HalfGridTitleSection>
                  <HalfGridTitle>Learning Path</HalfGridTitle>
                  <ViewAllText>{configJSON.ViewAll}</ViewAllText>
                </HalfGridTitleSection>
              </RightSections>
            </RightGrid>
          </BodySection>
          <ToastContainer
            icon={false}
            position="bottom-right"
            autoClose={false}
            closeOnClick={false}
            draggable={false}
          />
        </HeadDiv >
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const HeadDiv = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  transition: 'all 300ms linear',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  "& .Toastify__toast-container": {
    width: '35%'
  },
  "& .Toastify__close-button": {
    position: 'relative',
    top: 15,
    color: theme.palette.info.light,
    right: 20
  },
  "& .Toastify__toast": {
    borderRadius: '10px',
    background: theme.palette.primary.main
  },
  "& .Toastify__close-button > svg": {
    fill: theme.palette.info.light
  }
}))

const CalendarButton = styled(Button)(({ theme }) => ({
  maxWidth: '134px',
  height: '44px',
  padding: '10px 16px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.info.contrastText,
  textTransform: 'capitalize'
}))

const ExploreButton = styled(Button)(({ theme }) => ({
  maxWidth: '231px',
  height: '44px',
  padding: '10px 16px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.action.selected}`,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.secondary.main,
  background: theme.palette.action.selected,
  textTransform: 'capitalize'
}))

const SubSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 10
})

const SubHeadingSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
})

const SubHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '22px',
  fontSize: '14px',
  color: theme.palette.info.light
}))

const SubDescription = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '26px',
  fontSize: '18px',
  color: theme.palette.info.main
}))

const ImageIcon = styled('img')(({ theme }) => ({
  height: '42px',
  width: '42px'
}))

const VerticalLine = styled(Divider)(({ theme }) => ({
  height: '48px',
  marginRight: '8px',
  background: theme.palette.info.light
}))

const BodySection = styled(Grid)(({ theme }) => ({
  marginTop: '15px',
  maxWidth: '1070px'
}))

const HalfGrid = styled(Box)(({ theme }) => ({
  width: '50%',
  border: `1px solid ${theme.palette.secondary.dark}`,
  minHeight: '227px',
  borderRadius: '18px',
  maxWidth: '313px',
  gap: 10,
  margin: '0px 30px'
}))

const CourseImage = styled('img')({
  border: '18px',
  maxHeight: '111px',
  width: '100%'
})

const HalfGridTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '26px',
  textAlign: 'left',
  color: theme.palette.info.main
}))

const ViewAllText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '22px',
  color: theme.palette.info.contrastText,
  cursor: 'pointer'
}))

const HalfGridTitleSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '15px 30px'
})

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  margin: '10px',
  maxWidth: '285px',
  borderRadius: '10px',
  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: theme.palette.secondary.dark
  },
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: theme.palette.info.contrastText
  },
  "& .MuiLinearProgress-bar": {
    borderRadius: '9px'
  }
}))

const TitleCourse = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '10px',
  color: theme.palette.info.light
}))

const CourseValue = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  color: theme.palette.info.main
}))

const InternalSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '0px 10px'
}))

const FullGrid = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.dark}`,
  minHeight: '211px',
  borderRadius: '18px',
  margin: '30px',
  display: 'flex'
}))

const FullGridImage = styled('img')({
  width: '50%',
  maxWidth: '313px',
  height: '211px',
  borderRadius: '18px'
})

const LeftGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // margin: '30px 30px 30px 0px',
  background: theme.palette.action.focus,
  borderRadius: "18px",
  minWidth: '355px'
}))

const RightGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const RightSections = styled(Box)(({ theme }) => ({
  background: theme.palette.action.focus,
  borderRadius: "18px",
  minWidth: '355px',
  marginBottom: '20px'
}))

const NameField = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  color: theme.palette.info.main,
  lineHeight: '18px',
}))

const DesignationField = styled(Typography)(({ theme }) => ({
  fontSize: '11.51px',
  fontWeight: 700,
  color: theme.palette.secondary.light,
  lineHeight: '14.8px',
}))

const DetailsButton = styled(Button)(({ theme }) => ({
  padding: '10px 16px',
  maxWidth: '81px',
  height: '32px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  borderRadius: '30px',
  fontSize: '14px',
  fontFamily: 'Rubik',
  lineHeight: '22px',
  fontWeight: 700,
  color: theme.palette.info.contrastText,
  textAlign: 'left',
  marginLeft: '10px',
  textTransform: 'capitalize',
}))

const CourseSection = styled(Box)(({ theme }) => ({
  padding: '14.8px',
  borderRadius: '10px',
  display: 'flex',
  border: `1px solid ${theme.palette.text.hint}`,
  marginTop: '15px',
  flexDirection: 'column',
}))
// Customizable Area End
