import React from "react";
// Customizable Area Start
import { Box, Button, Typography,Grid, styled, TextField, InputAdornment, Badge } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowDropDownRounded, Search } from "@material-ui/icons";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgressWithLabel from '@material-ui/core/CircularProgress';
import { assignedImage, averageScoreIcon, backgroundImage, bellIcon, bulbIcon, certificateIcon, completedCoursesIcon, completedIcon, courseAddIcon, courseOne, courseThree, editIcon, enrollIcon, fileUploadIcon, mailIcon, mediaLibray1, mediaLibray2, mediaLibray3, mediaLibray4, messageIcon, mouseIcon, personButton, profileImage, progressIcon, taskPinIcon, teamMember, timeIcon, timerIcon, uploadCourseIcon, viewAllIcon } from "./assets";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { ManagerData, StudentCourseData, TeacherCourseData } from "./DashboardController.web";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,BarChart,Legend,Bar } from 'recharts';
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderWelcomeBar=(preferredName:string,roleId:string,studentDashboardIndexData:StudentCourseData,teacherDashboardIndexData:TeacherCourseData,managerDashboardIndexData:ManagerData)=>{
    if(roleId==="student"){
      return (
        <WelcomeBar>
              <WelcomeGrid container>
                  <Grid item md={6}>
                    <WelcomeBox>
                      <WelcomeTypography data-test-id="nameTest">Welcome Back, {preferredName}</WelcomeTypography>
                      <WelcomeSubTypography>There's new courses you can explore them now</WelcomeSubTypography>
                    </WelcomeBox>
                  </Grid>
                  <Grid item md={6}>
                    <ButtonBox>
                      <TaskListButton><img src={taskPinIcon} style={{width:"24px",height:"24px"}}/>Tasks List</TaskListButton>
                      <LeaderBoardButton><img src={mouseIcon}  style={{width:"24px",height:"24px"}}/>Explore Leaderboard</LeaderBoardButton>
                    </ButtonBox>
                  </Grid>
              </WelcomeGrid>
              <AwardGrid container>
                  <Grid item md={2}>
                    <AwardListBox>
                      <img src={progressIcon}  style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>In Progress</ItemHeading>
                        <ItemCount>{studentDashboardIndexData["progress_course_count"]} Courses</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
                  <Grid item md={2}>
                    <AwardListBox>
                    <img src={completedIcon}  style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>Completed</ItemHeading>
                        <ItemCount>{studentDashboardIndexData["completed_course_count"]} Courses</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
                  <Grid item md={2}>
                    <AwardListBox>
                    <img src={averageScoreIcon}  style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>Average Score</ItemHeading>
                        <ItemCount>{studentDashboardIndexData["average_score"]}</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
                  <Grid item md={2}>
                    <AwardListBox>
                    <img src={timeIcon}  style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>Watch Time</ItemHeading>
                        <ItemCount>{studentDashboardIndexData["watch_time"]}</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
                  <Grid item md={2}>
                    <AwardListBox>
                    <img src={certificateIcon}  style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>Certificates</ItemHeading>
                        <ItemCount>{studentDashboardIndexData["certificate_count"]}</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
               </AwardGrid>
            </WelcomeBar>
      )
    }
    else if(roleId==="educator"){
      return (
        <WelcomeBar>
              <WelcomeGrid container>
                  <Grid item md={6}>
                    <WelcomeBox>
                      <WelcomeTypography data-test-id="educatorName">Welcome Back, {preferredName}</WelcomeTypography>
                      <WelcomeSubTypography>Upload your first course to start engaging students!</WelcomeSubTypography>
                    </WelcomeBox>
                  </Grid>
                  <Grid item md={6}>
                    <ButtonBox>
                      <TaskListButton><img src={taskPinIcon} style={{width:"24px",height:"24px"}}/>Tasks List</TaskListButton>
                      <EditButton><img src={editIcon} style={{width:"24px",height:"24px"}}/></EditButton>
                      <CreateCourseButton><img src={courseAddIcon} style={{width:"24px",height:"24px"}}/>Create Courses</CreateCourseButton>
                    </ButtonBox>
                  </Grid>
              </WelcomeGrid>
              <AwardGrid container>
                  <Grid item md={3}>
                    <AwardListBox>
                      <img src={uploadCourseIcon} style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>Uploaded Co.</ItemHeading>
                        <ItemCount>{teacherDashboardIndexData["uploaded_course_count"]} Courses</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
                  <Grid item md={3}>
                    <AwardListBox>
                    <img src={fileUploadIcon} style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>Uploaded Assign.</ItemHeading>
                        <ItemCount>{teacherDashboardIndexData["assigned_course_count"]} Assign.</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
                  <Grid item md={3}>
                    <AwardListBox>
                    <img src={completedCoursesIcon} style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>Completed Co.</ItemHeading>
                        <ItemCount>{teacherDashboardIndexData["completed_course_percentage"]} %</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
                  <Grid item md={3}>
                    <AwardListBox>
                    <img src={enrollIcon} style={{width:"42.94px",height:"42.94px"}}/>
                      <AwardListItemBox>
                        <ItemHeading>Active Enrollments</ItemHeading>
                        <ItemCount>{teacherDashboardIndexData["active_enrollment"]} Enrollments</ItemCount>
                      </AwardListItemBox>
                    </AwardListBox>
                  </Grid>
               </AwardGrid>
            </WelcomeBar>
      )
    }
    else if(roleId==="supervisor/manager"){
        return (
          <WelcomeBar>
                <WelcomeGrid container>
                    <Grid item md={6}>
                      <WelcomeBox>
                        <WelcomeTypography data-test-id="managerTest">Welcome Back, {preferredName}</WelcomeTypography>
                        <WelcomeSubTypography>We've launched a new analytics dashboard !</WelcomeSubTypography>
                      </WelcomeBox>
                    </Grid>
                    <Grid item md={6}>
                      <ButtonBox>
                        <TaskListButton><img src={taskPinIcon} style={{width:"24px", height:"24px"}}/>Tasks List</TaskListButton>
                        <PersonButton><img src={personButton} style={{width:"24px", height:"24px"}}/></PersonButton>
                      </ButtonBox>
                    </Grid>
                </WelcomeGrid>
                <AwardGrid container>
                    <Grid item md={2}>
                      <AwardListBox>
                        <img src={enrollIcon} style={{width:"42.94px", height:"42.94px"}}/>
                        <AwardListItemBox>
                          <ItemHeading>Team Memebers</ItemHeading>
                          <ItemCount>{managerDashboardIndexData["team_members_count"]} Members</ItemCount>
                        </AwardListItemBox>
                      </AwardListBox>
                    </Grid>
                    <Grid item md={2}>
                      <AwardListBox>
                      <img src={bulbIcon} style={{width:"42.94px", height:"42.94px"}}/>
                        <AwardListItemBox>
                          <ItemHeading>Learning Goals</ItemHeading>
                          <ItemCount>{managerDashboardIndexData["learning_goal_count"]} Goals</ItemCount>
                        </AwardListItemBox>
                      </AwardListBox>
                    </Grid>
                    <Grid item md={2}>
                      <AwardListBox>
                      <img src={averageScoreIcon} style={{width:"42.94px", height:"42.94px"}}/>
                        <AwardListItemBox>
                          <ItemHeading>Average Score</ItemHeading>
                          <ItemCount>{managerDashboardIndexData["average_score"]} Points</ItemCount>
                        </AwardListItemBox>
                      </AwardListBox>
                    </Grid>
                    <Grid item md={2}>
                      <AwardListBox>
                      <img src={timerIcon} style={{width:"42.94px", height:"42.94px"}}/>
                        <AwardListItemBox>
                          <ItemHeading>Av Completion</ItemHeading>
                          <ItemCount>{managerDashboardIndexData["average_completion"]} %</ItemCount>
                        </AwardListItemBox>
                      </AwardListBox>
                    </Grid>
                    <Grid item md={2}>
                      <AwardListBox>
                      <img src={certificateIcon} style={{width:"42.94px", height:"42.94px"}}/>
                        <AwardListItemBox>
                          <ItemHeading>Average Cert.</ItemHeading>
                          <ItemCount>{managerDashboardIndexData["average_certificate"]} Certificates</ItemCount>
                        </AwardListItemBox>
                      </AwardListBox>
                    </Grid>
                 </AwardGrid>
              </WelcomeBar>
      )
    }
  }
  renderEducatorChart=(chartData:{month: string; completed_courses: number; remaining_courses: number;}[])=>{
    return (
        <>
          <ResponsiveContainer width="100%" height="70%">
        <BarChart
          layout="vertical"
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={true} horizontal={false} />
          <XAxis 
            type="number" 
            axisLine={false} 
            tickLine={false} 
            tick={true} 
          />
          <YAxis 
            type="category" 
            dataKey="month" 
            />
          <Tooltip />
          <Legend />
          <Bar dataKey="completed_courses" stackId="a" fill="#6683BD" radius={[0, 10, 10, 0]} />
          <Bar dataKey="remaining_courses" stackId="a" fill="#DDE1E6" radius={[0, 10, 10, 0]} />
        </BarChart>
      </ResponsiveContainer>
        </>
    )
  }
  renderManagerChart=(chartData:{name:string,averageScore:number}[])=>{
    return (
      <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={400}
        height={400}
        data={chartData}
        margin={{ top: 15, right: 30, left: 10, bottom: 0 }}
      >
        <defs>
          <linearGradient id="gradientColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="38.59%" stopColor="#6683BD" stopOpacity={1} />
            <stop offset="100%" stopColor="rgba(102, 131, 189, 0)" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
        <XAxis dataKey="averageScore"  type="number"/>
        <YAxis dataKey="name" type="category" axisLine={false}/>
        <Tooltip />
        <Area type="monotone" dataKey="name" stroke="#8884d8" fill="url(#gradientColor)" />
      </AreaChart>
    </ResponsiveContainer>
    )

  }
  renderStudentCourses=(studentDashboardIndexData:StudentCourseData,timeConvert:(date:string)=>string)=>{
    if(studentDashboardIndexData.progress_courses.data.length===0){
      return (
        <NoDataBox>
          <MessageBox>
            <ReachOutText data-test-id="reachOutTest">"Reach out to your manager.. etc."</ReachOutText>
            <EmailInfoText  data-test-id="emailInfoNode">Message admin or support "info@petropal.ai"</EmailInfoText>
          </MessageBox>
        </NoDataBox>
      )
    }
    else {
      return (
        <AllCoursesBox>
          <CourseHeadingBox>
            <CourseHeading  data-test-id="coursenameTest">Course</CourseHeading>
            <ViewLink>View All</ViewLink>
          </CourseHeadingBox>
          <TwoCourseBox>
            {studentDashboardIndexData.progress_courses.data.slice(0,2).map((course)=>(
              <CourseBox>
                {this.courseBox(course.attributes.course.data.attributes.course_name,course.attributes.progress_percentage,course.attributes.course.data.attributes.preferred_name)}
              </CourseBox>
            )
            )}
          </TwoCourseBox>
          {
            studentDashboardIndexData.progress_courses.data.length>2 ? (
            <ThirdCourseBox>
            <img src={courseThree}/>
            <ThirdCourseDetails>
            <InstructorBox>
                <InstructorNameBox>
                  <InstructorNameHeading>Course Name</InstructorNameHeading>
                  <InstructorName data-test-id="InstructorName">{studentDashboardIndexData.progress_courses.data[2].attributes.course.data.attributes.course_name}</InstructorName>
                </InstructorNameBox>
                <img src={viewAllIcon} style={{width:"28px",height:"28px"}}/>
              </InstructorBox>
              <InstructorBox>
                <InstructorNameBox>
                  <InstructorNameHeading>Instructor Name</InstructorNameHeading>
                  <InstructorName>{studentDashboardIndexData.progress_courses.data[2].attributes.course.data.attributes.preferred_name}</InstructorName>
                </InstructorNameBox>
              </InstructorBox>
              <InstructorBox>
                <InstructorNameBox>
                  <InstructorNameHeading>Lessons</InstructorNameHeading>
                  <InstructorName>7/16</InstructorName>
                </InstructorNameBox>
              </InstructorBox>
              <InstructorBox>
                <InstructorNameBox>
                  <InstructorNameHeading>Started Date</InstructorNameHeading>
                  <InstructorName>mm-dd-yy</InstructorName>
                </InstructorNameBox>
              </InstructorBox>
              <InstructorBox>
                <InstructorNameBox>
                  <InstructorNameHeading>End Date</InstructorNameHeading>
                  <InstructorName>{timeConvert(studentDashboardIndexData.progress_courses.data[2].attributes.expiration_date)}</InstructorName>
                </InstructorNameBox>
                <Percentage>{studentDashboardIndexData.progress_courses.data[2].attributes.progress_percentage}</Percentage>
              </InstructorBox>
              <LinearProgressBar variant="determinate" value={studentDashboardIndexData.progress_courses.data[2].attributes.progress_percentage}></LinearProgressBar>
            </ThirdCourseDetails>
          </ThirdCourseBox>
          ): (<></>)}
          </AllCoursesBox>
      )
    }
  }
  courseBox=(course_name:string,progress_percentage:string,educator_name:string)=>{
    return (
      <>
        <img src={courseOne} style={{width:"100%",height:"111.5px",borderRadius:"18px"}}/>
              <LinearProgressBar variant="determinate" value={+progress_percentage}></LinearProgressBar>
              <CourseNameBox>
                <CourseNameHeading>Course Name</CourseNameHeading>
                <Percentage>{progress_percentage}</Percentage>
              </CourseNameBox>
              <CourseName>{course_name}</CourseName>
              <InstructorBox>
                <InstructorNameBox>
                  <InstructorNameHeading>Instructor Name</InstructorNameHeading>
                  <InstructorName>{educator_name}</InstructorName>
                </InstructorNameBox>
                <img src={viewAllIcon} style={{width:"28px",height:"28px"}}/>
              </InstructorBox>
      </>
    )
  }
  teacherCourseBox=(course_name:string)=>{
    return (
          <TeacherDetailBox>
            <img src={courseOne} style={{width:"307px",height:"106px",borderRadius:"18px"}}/>
              <TeacherCourseNameBox>
                <CourseNameHeading>Course Name</CourseNameHeading>
                <CourseName>{course_name}</CourseName>
                <InstructorNameBox>
                  <InstructorNameHeading>Course Lessons</InstructorNameHeading>
                  <InstructorName>16 Lessons</InstructorName>
                </InstructorNameBox>
                </TeacherCourseNameBox>
                <TeacherStatusBox>
                <img src={viewAllIcon} style={{width:"28px",height:"28px"}}/>
                  <InProgressTypoBox>
                    <InProgressTypo>ACTIVE</InProgressTypo>
                  </InProgressTypoBox>
                </TeacherStatusBox>
          </TeacherDetailBox>
    )
  }
  renderTeamDetailBox=()=>{
    return (
      <TeamDetailBox>
                <LargeTeamBox>
                  <img src={teamMember} style={{width:"34px",height:"34px"}}/>
                  <LargeTeamNameBox>
                    <DateHeadingTypography>Dep./Role</DateHeadingTypography>
                    <TeamNameTypography>Ahmed Magdy</TeamNameTypography>
                  </LargeTeamNameBox>
                </LargeTeamBox>
                <LargeTeamNameBox>
                    <DateHeadingTypography>Course Name</DateHeadingTypography>
                    <TeamNameTypography>UI/UX Principles</TeamNameTypography>
                </LargeTeamNameBox>
                <LargeTeamStatusBox>
                  <DateHeadingTypography>Status</DateHeadingTypography>
                    <InProgressTypoBox>
                      <InProgressTypo>ON GOING</InProgressTypo>
                    </InProgressTypoBox>
                </LargeTeamStatusBox>
                <CircularProgressBox>
                    <CircularProgressBar variant="determinate" value={60} thickness={5} />
                    <CircularProgressTypoBox>
                      <CircularProgressTypography>60%</CircularProgressTypography>
                    </CircularProgressTypoBox>
                </CircularProgressBox>
                <img src={viewAllIcon} style={{width:"34px",height:"34px"}}></img>
                </TeamDetailBox>
    )
  }
  renderManagerCourses=(managerDashboardIndexData:ManagerData)=>{
    if(managerDashboardIndexData.team_members.data.length===0){
      return (
        <NoDataBox>
          <MessageBox>
            <ReachOutText data-test-id="managerReachOut">"Reach out to your manager.. etc."</ReachOutText>
            <EmailInfoText>Message admin or support "info@petropal.ai"</EmailInfoText>
          </MessageBox>
        </NoDataBox>
      )
    }
    else {
      return (<>
        <AllManagerBox>
          <CourseHeadingBox>
            <CourseHeading data-test-id="ManagerDataTest">Team Members/Team Name</CourseHeading>
            <ViewLink>View All</ViewLink>
          </CourseHeadingBox>
          <TwoManagerTeamBox>
            {managerDashboardIndexData.team_members.data.slice(0,2).map((team)=>(
              <ManagerTeamBox>
                {this.renderTeamDetailBox()}
                {this.renderTeamDetailBox()}
              </ManagerTeamBox>
           ))} 
          </TwoManagerTeamBox>
          </AllManagerBox>
          <ManagerGraphBox>
          <CourseHeadingBox>
            <CourseHeading data-test-id="managerGraph">Average Scores Per Course</CourseHeading>
            <ViewLink>View All</ViewLink>
          </CourseHeadingBox>
            {this.renderManagerChart([ 
            { name: 'Courses 1', averageScore: 68 },
            { name: 'Courses 2', averageScore: 47 },
            { name: 'Courses 3', averageScore: 39 },])}
          </ManagerGraphBox>
      </>
      )
    }
  }
  renderTeacherCourses=(teacherDashboardIndexData:TeacherCourseData)=>{
    if(teacherDashboardIndexData.courses.data.length===0){
      return (
        <NoDataBox>
          <MessageBox>
            <ReachOutText data-test-id="teacherReachOut">"Reach out to your manager.. etc."</ReachOutText>
            <EmailInfoText>Message admin or support "info@petropal.ai"</EmailInfoText>
          </MessageBox>
        </NoDataBox>
      )
    }
    else {
      return (
        <>        
        <AllTeacherCoursesBox>
          <CourseHeadingBox>
            <CourseHeading data-test-id="TeacherCourseDataTest">Course</CourseHeading>
            <ViewLink>View All</ViewLink>
          </CourseHeadingBox>
          <TwoTeacherCourseBox>
            {teacherDashboardIndexData.courses.data.slice(0,2).map((course)=>(
              <TeacherCourseBox>
              {this.teacherCourseBox(course.attributes.course_name)}
              {this.teacherCourseBox(course.attributes.course_name)}
            </TeacherCourseBox>
            )
            )}
          </TwoTeacherCourseBox>
          </AllTeacherCoursesBox>
          <EducatorGraphBox>
          <CourseHeadingBox>
            <CourseHeading data-test-id="TeacherGraphDataTest">Average Scores Per Course</CourseHeading>
            <ViewLink>View All</ViewLink>
          </CourseHeadingBox>
          {this.renderEducatorChart([
                  {
                    month: 'Jan',
                    completed_courses: 4000,
                    remaining_courses: 2400,
                  },
                  {
                    month: 'Feb',
                    completed_courses: 3000,
                    remaining_courses: 1100,
                  },
                  {
                    month: 'Mar',
                    completed_courses: 2000,
                    remaining_courses: 400,
                  },])}
          </EducatorGraphBox>
        </>

      )
    }
  }
  renderBulletinBox=()=>{
    return(
      <BulletinBox>
        <CourseHeadingBox>
          <CourseHeading>Bulletin Board</CourseHeading>
          <ViewLink>View All</ViewLink>
        </CourseHeadingBox>
        <HighScoreBox>
          <HighScoreInfoBox>
            <InfoHeading>Achieving highest score</InfoHeading>
            <InfoDetail>Text about the goal that settled before by the manage...</InfoDetail>
          </HighScoreInfoBox>
          <HighScoreImageBox>
            <img src={viewAllIcon} style={{width:"28px",height:"28px"}}/>
          </HighScoreImageBox>
        </HighScoreBox>
      </BulletinBox>
    )
  }
  renderTeamGoalList=()=>{
    return (
      <IndividualGoalTeamBox>
        <SmallTeamBox>
          <img src={teamMember} style={{width:"34px",height:"34px"}}/>
          <SmallTeamNameBox>
            <DateHeadingTypography>Dep./Role</DateHeadingTypography>
            <TeamNameTypography>Ahmed Magdy</TeamNameTypography>
          </SmallTeamNameBox>
        </SmallTeamBox>
        <SmallTeamStatusBox>
          <DateHeadingTypography>Status</DateHeadingTypography>
            <InProgressTypoBox>
              <InProgressTypo>ACTIVE</InProgressTypo>
            </InProgressTypoBox>
        </SmallTeamStatusBox>
            <img src={messageIcon} style={{width:"34px",height:"34px"}}/>
      </IndividualGoalTeamBox>
              
    )
  }
  renderMainBoard=(roleId:string,studentDashboardIndexData:StudentCourseData,teacherDashboardIndexData:TeacherCourseData,managerDashboardIndexData:ManagerData,timeConvert:(date:string)=>string)=>{
    if(roleId==="student"){
      return (
        <MainCourseBox>
            <Grid container>
              <Grid item md={8} style={{padding:"20px"}}>
              {this.renderStudentCourses(studentDashboardIndexData,timeConvert)}       
              </Grid>
              { studentDashboardIndexData.progress_courses.data.length===0 ? <></> : 
              <Grid item md={4} style={{padding:"20px"}}>
                <AllMessageBox>
                  <LearningBox>
                  <CourseHeadingBox>
                    <CourseHeading>Learning Path</CourseHeading>
                    <ViewLink>View All</ViewLink>
                  </CourseHeadingBox>
                  <HighScoreBox>
                    <HighScoreInfoBox>
                      <InfoHeading>Achieving highest score</InfoHeading>
                      <InfoDetail>Text about the goal that settled before by the manage...</InfoDetail>
                    </HighScoreInfoBox>
                    <HighScoreImageBox>
                      <img src={viewAllIcon} style={{width:"28px",height:"28px"}}/>
                      <InProgressTypoBox>
                        <InProgressTypo>IN PROGRESS</InProgressTypo>
                      </InProgressTypoBox>
                    </HighScoreImageBox>
                  </HighScoreBox>
                  </LearningBox>
                  <AssignedBox>
                    <CourseHeadingBox>
                      <CourseHeading>Assigned Courses</CourseHeading>
                      <ViewLink>View All</ViewLink>
                    </CourseHeadingBox>
                    <AssignedBoxCourse>
                      <img src={studentDashboardIndexData.assigned_courses.data[0].attributes.course.data.attributes.image} style={{width:"118px",height:"78px",borderRadius:"18px"}}/>
                      <AssignBoxCourseDetails>
                      <AssignedCourseNameBox>
                        <CourseNameHeading>Course Name</CourseNameHeading>
                        <CourseName data-test-id="firstCourseTest">{studentDashboardIndexData.assigned_courses.data[0].attributes.course.data.attributes.course_name}</CourseName>
                      </AssignedCourseNameBox>
                      <InstructorBox>
                        <InstructorNameBox>
                          <InstructorNameHeading>Instructor Name</InstructorNameHeading>
                          <InstructorName>{studentDashboardIndexData.assigned_courses.data[0].attributes.course.data.attributes.preferred_name}</InstructorName>
                        </InstructorNameBox>
                        <img src={viewAllIcon} style={{width:"28px",height:"28px"}}/>
                      </InstructorBox>
                      </AssignBoxCourseDetails>
                    </AssignedBoxCourse>
                  </AssignedBox>
                  {this.renderBulletinBox()}
                </AllMessageBox>
              </Grid>
    }
            </Grid>
          </MainCourseBox>
      )
    } else if(roleId==="educator"){
      return (
          <MainCourseBox>
              <Grid container>
                <Grid item md={8} style={{padding:"20px"}}>
                {this.renderTeacherCourses(teacherDashboardIndexData)}       
                </Grid>
                {teacherDashboardIndexData.courses.data.length===0 ? <></> : 
                <Grid item md={4} style={{padding:"20px"}}>
                  <AllMessageBox>
                    <MediaBox>
                    <CourseHeadingBox>
                      <CourseHeading>Media Library</CourseHeading>
                      <ViewLink>View All</ViewLink>
                    </CourseHeadingBox>
                    <MediaLibraryBox>
                      <Grid container>
                        <Grid item md={7}>
                          <img src={mediaLibray1} style={{ width: "170.34px", height: "118px", borderRadius: "7.05px"}}/>
                        </Grid>
                        <Grid item md={5}>
                          <img src={mediaLibray2} style={{ width: "130.68px", height: "118px", borderRadius: "7.05px"}}/>
                        </Grid>
                        <Grid item md={4}>
                          <img src={mediaLibray3} style={{ width: "95.53px", height: "143px", borderRadius: "7.05px"}}/>
                        </Grid>
                        <Grid item md={8}>
                          <img src={mediaLibray4} style={{ width: "203.69px", height: "143px", borderRadius: "7.05px"}}/>
                        </Grid>
                      </Grid>
                    </MediaLibraryBox>
                    </MediaBox>
                    {this.renderBulletinBox()}
                  </AllMessageBox>
                </Grid>
            }
            </Grid>
      
            </MainCourseBox>
        )
      
    }
    else if(roleId==="supervisor/manager"){
      return (
        <MainCourseBox>
        <Grid container>
          <Grid item md={8} style={{padding:"20px"}}>
          {this.renderManagerCourses(managerDashboardIndexData)}       
          </Grid>
          { managerDashboardIndexData.team_members.data.length===0 ? <></>: 
          <Grid item md={4} style={{padding:"20px"}}>
            <AllMessageBox>
              <ManagerMessageBox>
              <CourseHeadingBox>
                <CourseHeading>Team Goals</CourseHeading>
                <ViewLink>View All</ViewLink>
              </CourseHeadingBox>
              <TeamGoalBox>
                <HighScoreInfoBox>
                  <InfoDetail>The sales department will improve customer...</InfoDetail>
                </HighScoreInfoBox>
                <GoalProgressBox>
                  <DateBox>
                    <DateHeadingTypography>Due Date</DateHeadingTypography>
                    <DateTypography>8 Sep, 2020</DateTypography>
                  </DateBox>
                  <DateBox>
                    <DateHeadingTypography>Status</DateHeadingTypography>
                      <InProgressTypoBox>
                        <InProgressTypo>IN PROGRESS</InProgressTypo>
                      </InProgressTypoBox>
                  </DateBox>
                  <DateBox>
                  <CircularProgressBox>
                    <CircularProgressBar variant="determinate" value={60} thickness={5} />
                    <CircularProgressTypoBox>
                      <CircularProgressTypography>60%</CircularProgressTypography>
                    </CircularProgressTypoBox>
                 </CircularProgressBox>
                  </DateBox>
                </GoalProgressBox>
              </TeamGoalBox>
              </ManagerMessageBox>
              <IndividualGoalBox>
                <GoalHeadingBox>
                  <CourseHeading>Individual Goals</CourseHeading>
                  <ViewLink>View All</ViewLink>
                </GoalHeadingBox>
                {this.renderTeamGoalList()}
                {this.renderTeamGoalList()}
                {this.renderTeamGoalList()}
                </IndividualGoalBox>
            </AllMessageBox>
          </Grid>
          }
        </Grid>
      </MainCourseBox>
      )
    }
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
      <HamburgerMenu {...this.props} open={this.state.open} handleMenu={this.handleMenu}  themeDark={this.state.darkTheme} handleTheme={this.handleTheme}/>
          <MainContainer data-test-id="mainContainer" style={{paddingLeft: this.state.open ? 190 : 50}}>
            <NavBar>
              <Grid container spacing={4}>
                <NavGrid item md={4} xs={4}>
                  <SearchBar
                    variant="outlined"
                    placeholder="Search"
                   InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{color:"#8C8C8C"}}/>
                      </InputAdornment>
                    ),
                  }}/>
                </NavGrid>
                <NavGrid item md={4} lg={4}>
                  <NavLinkBox>
                    <NavLinkActiveTypography>Dashboard</NavLinkActiveTypography>
                    <NavLinkTypography>Courses</NavLinkTypography>
                    <NavLinkTypography>Community</NavLinkTypography>
                  </NavLinkBox>
                </NavGrid>
                <NavGrid item>
                  <ProfileBox>
                    <BadgeContainer badgeContent={0} color="secondary">
                      <img src={mailIcon} style={{width:"24px",height:"24px"}}/>
                    </BadgeContainer>
                    <BadgeContainer badgeContent={0} color="secondary">
                      <img src={bellIcon}  style={{width:"24px",height:"24px"}}/>
                    </BadgeContainer>
                    <ProfileImageNameBox>
                      <img src={profileImage} style={{width:"40px",height:"40px"}}/>
                      <NameBox>
                        <NameTypography>{this.state.profileData.attributes.preferred_name}</NameTypography>
                        <PositionTypography>{this.state.profileData.attributes.role_id}</PositionTypography>
                      </NameBox> 
                      <ArrowDropDownRounded style={{color:"#1F1F1F"}}/> 
                    </ProfileImageNameBox>
                  </ProfileBox>
                </NavGrid>
              </Grid>
            </NavBar>
            {this.renderWelcomeBar(this.state.profileData.attributes.preferred_name,this.state.profileData.attributes.role_id,this.state.studentDashboardIndexData,this.state.teacherDashboardIndexData,this.state.managerDashboardIndexData)}
            {this.renderMainBoard(this.state.profileData.attributes.role_id,this.state.studentDashboardIndexData,this.state.teacherDashboardIndexData,this.state.managerDashboardIndexData,this.timeConvert)}
          </MainContainer>  
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: theme.palette.background.default,
  height: 'inherit',
  '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main
  }
}))
const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 4.4,
  borderRadius: 9.09,
  [`& .MuiLinearProgress-colorPrimary`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.action.selected,
  },
}));
const IndividualGoalBox = styled(Box)(({ theme }) => ({
  width: "359px",
  height: "320px",
  borderRadius: "18px",
  display: "flex",
  backgroundColor:theme.palette.background.paper,
  flexDirection: "column",
  gap: "0px"
}))
const CircularProgressBox = styled(Box)(({theme})=>({
  position: "relative", display: "inline-flex"
}))
const CircularProgressTypoBox = styled(Box)(({theme})=>({
  top:"0",
  left:"0",
  right:"0",
  bottom:"0",
  position:"absolute",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
}))
const CircularProgressBar = styled(CircularProgressWithLabel)(({ theme }) => ({
  width: "45px !important",
  height: "41.67px !important",
  transform:"rotate(90deg) !important",
  borderRadius:"20px",
  [`&&`]: {
    color: `${theme.palette.action.selected} !important`,
    borderRadius:"20px",
   },
  [`& .MuiCircularProgress-colorPrimary`]: {
    width: "45px !important",
    height: "41.67px !important",
    borderRadius:"20px",
    color: `${theme.palette.secondary.main} !important`,
  }
}));
const CircularProgressTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "6.97px",
  fontWeight: 400,
  lineHeight: "8.28px",
  textAlign: "center",
  color: theme.palette.text.hint
}))
const CourseBox = styled(Box)(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "18px",
  height: "222px",
  display: "flex",
  flexDirection: "column",
  gap: "8px"
}))
const TeacherCourseBox = styled(Box)(({ theme }) => ({
  width: "313px",
  border: "1px solid rgb(238, 238, 238)",
  borderRadius: "18px",
  height: "222px",
  display: "flex",
  flexDirection: "column",
}))
const ManagerTeamBox = styled(Box)(({ theme }) => ({
  width: "313px",
  borderRadius: "18px",
  height: "222px",
  display: "flex",
  flexDirection: "column",
 }))
const AssignedBoxCourse = styled(Box)(({ theme }) => ({
  width: "303px",
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "18px",
  height: "78px",
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  margin:"16px"
}))
const AssignBoxCourseDetails = styled(Box)(({ theme }) => ({
  width: "175px",
  borderRadius: "18px",
  height: "78px",
  gap: "8px",
  display:"flex",
  flexDirection:"column",
  margin:"16px",
}))
const CourseNameBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  flexDirection: "row"
}))
const TeacherCourseNameBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",
  flexDirection: "column"
}))
const AssignedCourseNameBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  flexDirection: "column",
  backgroundColor:theme.palette.background.paper,
}))
const InstructorBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}))
const HighScoreBox = styled(Box)(({ theme }) => ({
  borderLeft: `4px solid ${theme.palette.info.contrastText}`,
  width: "308px",
  height: "72px",
  top: "70px",
  left: "29px",
  padding: "6px 12px 6px 16px",
  gap: "8px",
  borderRadius: "6px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  margin:"16px",
}))
const TeamGoalBox = styled(Box)(({ theme }) => ({
  width: "303px",
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "18px",
  height: "78px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  margin:"16px"
}))

const TeacherDetailBox = styled(Box)(({ theme }) => ({
  width: "630px",
  height: "106px",
  padding: "8px 16px 8px 16px",
  borderRadius: "18px",
  justify: "space-between",
  border: `0.5px solid ${theme.palette.secondary.main}`,
  display: "flex",
  flexDirection: "row",
  margin:"16px",
  justifyContent:"center",
  gap:"30px",
  alignItems:"center",
}))
const TeamDetailBox = styled(Box)(({ theme }) => ({
  width: "628px",
  height: "77.92px",
  padding: "8px 16px 8px 16px",
  borderRadius: "18px",
  justify: "space-between",
  border: `0.5px solid ${theme.palette.secondary.main}`,
  display: "flex",
  flexDirection: "row",
  gap: "30px",
  margin:"16px",
  justifyContent:"center",
  alignItems:"center",
}))
const IndividualGoalTeamBox = styled(Box)(({ theme }) => ({
  width: "303px",
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "18px",
  height: "78px",
  display: "flex",
  flexDirection: "row",
  gap: "30px",
  margin:"16px",
  justifyContent:"center",
  alignItems:"center",
}))
const MediaLibraryBox = styled(Box)(({ theme }) => ({
  height:"85%",
  margin:"12px",
  display:"flex",
}))

const HighScoreInfoBox = styled(Box)(({ theme }) => ({
  width: "90%",
}))

const HighScoreImageBox = styled(Box)(({ theme }) => ({
  width: "40%",
  display:"flex",
  flexDirection:"column",
  justifyContent:"space-between",
  gap:"10px",
  alignItems:"end",
}))
const GoalProgressBox = styled(Box)(({ theme }) => ({
  width: "90%",
  display:"flex",
  flexDirection:"row",
  justifyContent:"space-between",
  gap:"10px",
  alignItems:"end",
}))

const InstructorNameBox = styled(Box)(({ theme }) => ({
  width: "90%",
}))
const MainCourseBox = styled(Box)(({ theme }) => ({
  width: "92%",display: "flex", flexDirection: "column", gap: "16px",marginLeft:"130px"
}))
const ThirdCourseDetails = styled(Box)(({ theme }) => ({
  width: "100%",display: "flex", flexDirection: "column", gap: "16px",
}))
const TwoCourseBox = styled(Box)(({ theme }) => ({
  width: "100%", height: "222px", borderRadius: "18px" ,display: "flex", flexDirection: "row", gap: "16px" 
}))
const TwoTeacherCourseBox = styled(Box)(({ theme }) => ({
  width: "100%", height: "222px", borderRadius: "18px" ,display: "flex", flexDirection: "column" 
}))
const TwoManagerTeamBox = styled(Box)(({ theme }) => ({
  width: "100%", height: "222px", borderRadius: "18px" ,display: "flex", flexDirection: "column",
}))
const ThirdCourseBox = styled(Box)(({ theme }) => ({
  width: "100%", height: "236px", borderRadius: "18px" ,display: "flex", flexDirection: "row", gap: "16px" ,border: `1px solid ${theme.palette.background.paper}`,
}))
const CourseHeadingBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}))
const GoalHeadingBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  marginTop: "20px",
  gap: "20px"
}))
const CourseHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const InfoHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const InfoDetail = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const InProgressTypoBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "22px",
  padding: "2px 6px 2px 6px",
  gap: "10px",
  borderRadius: "38px",
  opacity: "0px",
  backgroundColor: theme.palette.secondary.light
}))

const DateBox = styled(Box)(({ theme }) => ({
  width: "115px",
  height: "22px",
  padding: "2px 6px 2px 6px",
  gap: "10px",
  borderRadius: "38px",
  opacity: "0px",
}))
const SmallTeamBox = styled(Box)(({ theme }) => ({
  width: "138px",
  height: "53.74px",
  padding: "2px 6px 2px 6px",
  gap: "10px",
  borderRadius: "38px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center",
}))
const LargeTeamBox = styled(Box)(({ theme }) => ({
  width: "138px",
  height: "98.2px",
  padding: "2px 6px 2px 6px",
  gap: "10px",
  borderRadius: "38px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center",
}))
const LargeTeamNameBox = styled(Box)(({ theme }) => ({
  width: "96px",
  height: "36px",
  padding: "2px 6px 2px 6px",
  gap: "5px",
  borderRadius: "38px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column"
}))
const SmallTeamStatusBox = styled(Box)(({ theme }) => ({
  width: "59px",
  height: "48px",
  padding: "6px 0px 6px 0px",
  gap: "5px",
  display:"flex",
  flexDirection:"column"
}))
const TeacherStatusBox = styled(Box)(({ theme }) => ({
  width: "59px",
  height: "48px",
  padding: "6px 0px 6px 0px",
  gap: "5px",
  display:"flex",
  flexDirection:"column",
  alignItems:"flex-end",
  justifyContent:"center",
}))
const LargeTeamStatusBox = styled(Box)(({ theme }) => ({
  width: "73px",
  height: "44px",
  padding: "6px 0px 6px 0px",
  gap: "5px",
  display:"flex",
  flexDirection:"column"
}))
const SmallTeamNameBox = styled(Box)(({ theme }) => ({
  width: "96px",
  height: "36px",
  padding: "2px 6px 2px 6px",
  gap: "5px",
  borderRadius: "38px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column"
}))
const InProgressTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.action.focus
}))
const DateHeadingTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "11.85px",
  textAlign: "left",
  color: theme.palette.action.disabled
}))
const DateTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  width:"100px",
  color: theme.palette.text.hint
}))
const TeamNameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  width:"100px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const CourseNameHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "11.85px",
  textAlign: "left",
  color: theme.palette.action.disabled
}))
const InstructorNameHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "11.85px",
  textAlign: "left",
  color: theme.palette.action.disabled
}))
const InstructorName = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const CourseName = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const Percentage = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const ViewLink = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.action.selected
}))
const AllTeacherCoursesBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "317px",
  opacity: "0px",
  padding: "26px 16px",
  borderRadius:"18px",
  backgroundColor:theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  margin:"23px",
}))
const AllCoursesBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "547px",
  gap: "16px",
  opacity: "0px",
  padding: "26px 16px",
  borderRadius:"18px",
  backgroundColor:theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  margin:"23px",
}))
const AllManagerBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "267px",
  opacity: "0px",
  padding: "26px 16px",
  borderRadius:"18px",
  backgroundColor:theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  margin:"23px",
}))
const ManagerGraphBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "260px",
  opacity: "0px",
  padding: "26px 16px",
  borderRadius:"18px",
  backgroundColor:theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  margin:"23px",
}))
const EducatorGraphBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "260px",
  opacity: "0px",
  padding: "26px 16px",
  borderRadius:"18px",
  backgroundColor:theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  margin:"23px",
}))
const AllMessageBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height:"100%",
  gap: "20px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  margin:"23px",
}))
const LearningBox = styled(Box)(({ theme }) => ({
  width: "355px",
  height: "169px",
  gap: "0px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  padding:"16px",
}))
const ManagerMessageBox = styled(Box)(({ theme }) => ({
  width: "355px",
  height: "169px",
  gap: "0px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  padding:"16px",
}))
const MediaBox = styled(Box)(({ theme }) => ({
  width: "356px",
  height: "358px",
  borderRadius: "18px",
  backgroundColor: theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  padding:"16px",
}))
const AssignedBox = styled(Box)(({ theme }) => ({
  width: "355px",
  height: "169px",
  gap: "0px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  padding:"16px",
}))
const BulletinBox = styled(Box)(({ theme }) => ({
  width: "355px",
  height: "169px",
  gap: "0px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: theme.palette.background.paper,
  display:"flex",
  flexDirection:"column",
  padding:"16px",
}))
const NavBar = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "75px",
  padding: "15.79px 36.45px 15.79px 33.5px",
  borderRadius: "18px",
  border:"1px",
  background: theme.palette.background.paper,
  margin:"48px 26px",
}))
const WelcomeBar = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "186px",
  borderRadius: "18px",
  padding: "8px 12px 8px 12px",
  justify: "space-between",
  background: theme.palette.background.paper,
  margin:"23px",
}))
const NoDataBox = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "400px",
  justify: "space-between",
  backgroundImage:`url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display:"flex",
  justifyContent:"center",
  alignItems:"self-end",
}))
const MessageBox = styled(Box)(({ theme }) => ({
  gap: "14px",
  width: "634px",
  height: "209px",
  borderRadius: "8px 0px 0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  alignItems: "first baseline"
}))
const ReachOutText = styled(Typography)(({ theme }) => ({
  fontFamily: "Kallisto",
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "40px",
  letterSpacing: "-0.005em",
  textAlign: "left",
  color: theme.palette.secondary.dark
}))
const EmailInfoText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "28px",
  textAlign: "left",
  color: theme.palette.primary.light
}))
const WelcomeBox = styled(Box)(({ theme }) => ({
  width: "1,024.89px",
  height: "70px",
  padding: "8px 12px 8px 12px",
  gap: "0px",
  justify: "space-between",
  opacity: "0px",
  margin: "22px"
}))
const AwardListBox = styled(Box)(({ theme }) => ({
  borderLeft: "1px solid #E2E8F0",
  padding: "0px 8px 0px 8px",
  gap: "12px",
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center",
}))
const AwardListItemBox = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  flexDirection:"column",
}))
const ItemHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.action.disabled
}))
const ItemCount = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "26px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const CalendarButton = styled(Button)(({ theme }) => ({
  width: "134px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  textTransform:"capitalize",
  border: "1px solid #1A469C",
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "left",
    color: "#1A469C"
  }
}))
const TaskListButton = styled(Button)(({ theme }) => ({
  width: "160px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: "1px solid #1A469C",
  textTransform:"capitalize",
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "left",
    color: "#1A469C"
  }
}))
const EditButton = styled(Button)(({ theme }) => ({
  width: "56px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: "1px solid #1A469C",
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "left",
    color: "#1A469C"
  }
}))
const ButtonBox = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:"center",
  gap:"8px",
}))
const LeaderBoardButton = styled(Button)(({ theme }) => ({
  width: "256px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  textTransform:"capitalize",
  background: theme.palette.info.contrastText,
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#F8FAFC"
  }
}))
const PersonButton = styled(Button)(({ theme }) => ({
  width: "54px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  background: theme.palette.info.contrastText,
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#F8FAFC"
  }
}))
const CreateCourseButton = styled(Button)(({ theme }) => ({
  width: "256px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  background: theme.palette.info.contrastText,
  textTransform:"capitalize",
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#F8FAFC"
  }
}))
const WelcomeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Kallisto",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.005em",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const WelcomeSubTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.action.disabled
}))
const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex", flexDirection: "row", gap: "8px"
}))
const ProfileImageNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px"
}))
const NameBox = styled(Box)(({ theme }) => ({

}))
const NameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.main
}))
const PositionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.light,
  textTransform:"capitalize",
}))
const BadgeContainer = styled(Badge)(({ theme }) => ({
  width: "44px",
  height: "44px",
  padding: "10px",
  gap: "4px",
  borderRadius: "8px",
  opacity: "0px",
}))
const NavGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}))
const WelcomeGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center"
}))
const AwardGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center"
}))
const SearchBar = styled(TextField)(({ theme }) => ({
  width: '257px',
  height: '44px',
  padding: '10px 16px',
  gap: '8px',
  borderRadius: '98px',
  border: `1px solid ${theme.palette.info.dark}`,
  backgroundColor: theme.palette.background.default,
  '& .MuiInputBase-root': {
    padding: 0,
    height: '100%',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '8px',
  },
}))
const NavLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifyContent: "center"
}))
const NavLinkTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.text.secondary,
  "& .active":{
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    padding: "8px",
    gap: "10px",
    borderRadius: "0px 0px 8px 8px"
  }
}))
const NavLinkActiveTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.action.selected,
  borderBottom: `2px solid ${theme.palette.action.selected}`,
  padding: "8px",
  gap: "10px",
  borderRadius: "0px 0px 8px 8px"
}))
const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
      primary: {
          main: '#FFFFFF',
          light:"010101"
      },
      secondary: {
          main: '#E2E8F0',
          light:"#D1FAE5",
          dark:"#0F172A"
      },
      background: {
          default: '#FFFFFF',
          paper: 'rgba(238,238,238,1)'
      },
      text: {
          primary: '#334155',
          secondary: '#475569',
          hint: '#0F172A',
      },
      action: {
          active: '#F1F5F9',
          selected: '#1A469C',
          focus: '#059669',
          disabled: '#475569'
      },
      info: {
          main: "#21272A",
          light: '#848484',
          dark: '#CBD5E1',
          contrastText:'#1A469C'
      },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
      primary: {
          main: '#161616',
          light:"#BDCFF4"
      },
      secondary: {
          main: '#18212E',
          light:"#074525",
          dark:"#BDCFF4"
      },
      background: {
          default: '#0D0D0D',
          paper: 'rgba(26,26,26,1)',
      },
      text: {
          primary: '#D5DDF0',
          secondary: '#93A1B5',
          hint: '#D5DDF0'
      },
      action: {
          active: '#70A1FF',
          selected: '#70A1FF',
          focus: '#42EBB5',
          disabled: '#99A7BB'
      },
      info: {
          main: "#D5DDF0",
          light: '#99A7BB',
          dark: '#273444',
          contrastText:'#12326F'
      },
  },
  typography: FontStyle
});
const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};
// Customizable Area End
