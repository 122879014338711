// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  styled,
  InputAdornment,
  Grid,TextField,Badge,TextareaAutosize,Paper,
  Modal,
  Dialog,Menu,MenuItem
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { bellIcon, mailIcon,profileImage,addIcon, attachIcon, atIcon, cloudIcon,videoIcon, greenDot, viewAll,addSqureIcon, moreIcon, editIcon, trashIcon } from "./assets";
import { ArrowDropDownRounded, Search } from "@material-ui/icons";
import { NotesData,NoteDataResponse,Note } from "./ProjectnotesController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ProjectnotesController, {
  Props,
  configJSON,
} from "./ProjectnotesController";

export default class Projectnotes extends ProjectnotesController {
  constructor(props: Props) {
    super(props);
  }

  renderEmptyNotesBox=(handleNoteModal:any)=>{
    return (
      <>
      <MainNoteBox>
      <EmptyNotesBox>
        <NoNoteHeading>
          NO NOTES
        </NoNoteHeading>
        <NoNoteDetailsTypo>
          YOU HAVE NOT CREATE ANY NOTE YET TAP TO CREATE.
        </NoNoteDetailsTypo>
        <AddNoteButton onClick={handleNoteModal}>
          Add Note
          <img src={addIcon} style={{width:"24px",height:"24px"}}/>
        </AddNoteButton>
      </EmptyNotesBox>
      </MainNoteBox>
      </>
    )
  }
  renderNotesBoxes=(handleNoteModal:any,allNotes:NotesData,openIndividualNote:any)=>{
    return (
      <Box>
        <GroupBoxes>
          <Grid container>
          { this.renderAllNotesBox(handleNoteModal,allNotes,openIndividualNote) }
          </Grid>
        </GroupBoxes>
       </Box>
    )
  }
  renderAllNotesBox=(handleNoteModal:any,allNotes:NotesData,openIndividualNote:any)=>{
    
    return (<>  
    {allNotes.data.map((note)=>(
        <Grid xs={4} md={4} lg={4} style={{padding:"25px"}}>
        <NoteBox 
        onClick={handleNoteModal}
        >
          <NoteTitleBox>
            <img src={greenDot} style={{width:"11px",height:"11px"}}/>
            <NoteTitle data-test-id="noteTitleTest">{note.attributes.title}</NoteTitle>
            <img src={viewAll} style={{width:"28px",height:"28px"}} data-test-id="openIndTest"
            onClick={()=>openIndividualNote(note.id)}
            />
          </NoteTitleBox>
          <NoteDescription>
            {note.attributes.note_content}
          </NoteDescription>
          {
            note.attributes.images.length>0 ?
            <img src={note.attributes.images[0].url} 
            style={{width: "105px",
              height: "65px",
              gap: "16px",
              borderRadius: "12px",
              opacity: "0px",
              border: "0.5px solid #E2E8F0"}}/>
              : <></>
          }</NoteBox>
          </Grid>
      ))}
      </>
    )
  }
  renderIndividualNoteBox=(handleMoreMenu:any,closeMoreMenu:any,openMoreMenu:boolean,indNote:NoteDataResponse|[],editPost:any,handleDeleteProjectNote:any)=>{
    if(!(Array.isArray(indNote))){
      const noteData = indNote.data as Note;
      return (
        <Box>
        <IndividualNoteBox>
            <IndNoteTitleBox>
              <Box style={{display:"flex",flexDirection:"row",width:"220px",justifyContent:"space-between",alignItems:"center"}}>
                <img src={greenDot} style={{width:"11px",height:"11px"}}/>
                <NoteTitle data-test-id="individualTitle">{noteData.attributes.title}</NoteTitle>
              </Box>
              <Box>
              <img src={moreIcon} style={{width: "18.09px",
                                  height: "36.17px",
                                  padding: "8.22px 0px 0px 0px",
                                  gap: "6.58px",
                                  borderRadius: "39.46px",
                                  opacity: "0px"}}
                                  onClick={handleMoreMenu}
                                  data-test-id="moreMenuTest"
                                  />
              <MoreOptionsMenu open={openMoreMenu} onClose={closeMoreMenu}>
                <MenuItem data-test-id="editTest" onClick={()=>{editPost(noteData.id,noteData.attributes.title,noteData.attributes.note_content,noteData.attributes.images[0]?.url)}}>
                  <img src={editIcon} style={{width:"24px",height:"24px"}}/><EditTypo>Edit Post</EditTypo>
                  </MenuItem>
                <MenuItem data-test-id="deleteTest" onClick={()=>{handleDeleteProjectNote()}}>
                  <img src={trashIcon} style={{width:"24px",height:"24px"}}/><DeleteTypo>Delete Post</DeleteTypo>
                </MenuItem>
               </MoreOptionsMenu>
            </Box>
            </IndNoteTitleBox>
            <IndNoteDescription>
              {noteData.attributes.note_content}
            </IndNoteDescription>
            {
                (noteData.attributes.images[0]) ? 
                (<ImageBox>
                  <img src={noteData.attributes.images[0].url} style={{ width: "760px",
                              height: "198px",
                              gap: "0px",
                              borderRadius: "18px",
                              opacity: "0px"}}></img>
                </ImageBox>) : <></>
              }
         </IndividualNoteBox>
      </Box>
      )
    }
    else {
      return (<></>)
    }
    
  }
  renderHeaderNoteBar=(handleNoteModal:any,allNotes:NotesData,myNotes:NotesData,handleSectionChange:any,currentSection:string)=>{
    let headerFlag = false;
    if(allNotes.data.length>0 && allNotes.data[0].id!=='' &&currentSection === "all_notes"){
      headerFlag=true;
    } if(myNotes.data.length>0 && myNotes.data[0].id!=='' && currentSection === "my_notes"){
      headerFlag=true;
    }
    return (<>{
      (headerFlag) ?
      (
        <AllNotesBoxHeader>
        <BoxHeaderLink>
          <HeaderLinks>
            <TitleTypo style={{color:(currentSection==="all_notes"?"#1A469C":"#475569")}} onClick={()=>handleSectionChange("all_notes")}
            data-test-id="allNotesSectionTest">All Notes</TitleTypo>
            <TitleTypo style={{color:(currentSection==="my_notes"?"#1A469C":"#475569")}} onClick={()=>handleSectionChange("my_notes")}
            data-test-id="myNotesSectionTest">My Notes</TitleTypo>
          </HeaderLinks>
        </BoxHeaderLink>
        <AddButton data-test-id="addNewNoteButton" onClick={handleNoteModal}><img src={addSqureIcon} style={{width:"24px",height:"24px"}}/>Add New NOTE</AddButton>
      </AllNotesBoxHeader>  
      ) :
      (
        <NotesHeaderBox>
          <NotesTitleBox>
            <Title data-test-id="allNotesSectionTest"><TitleTypo style={{color:(currentSection==="all_notes"?"#1A469C":"#475569")}} onClick={()=>handleSectionChange("all_notes")}>All Notes</TitleTypo></Title>
            <Title data-test-id="myNotesSectionTest"><TitleTypo style={{color:(currentSection==="my_notes"?"#1A469C":"#475569")}} onClick={()=>handleSectionChange("my_notes")}>My Notes</TitleTypo></Title>
          </NotesTitleBox>
        </NotesHeaderBox>
      )
    }
    </>
    ) 
  }
  render() {
    let content = this.renderEmptyNotesBox(this.handleNoteModal);
    if(this.state.openIndSection){
      content = this.renderIndividualNoteBox(this.handleMoreMenu,this.closeMoreMenu,this.state.openMoreMenu,this.state.individualNote,this.editPost,this.handleDeleteProjectNote)
    } else if (this.state.allNotes.data.length>0 && this.state.currentSection==="all_notes" && this.state.allNotes.data[0].id!=='') {
      content = this.renderNotesBoxes(this.handleNoteModal,this.state.allNotes,this.openIndividualNote)
    } else if (this.state.myNotes.data.length>0 && this.state.currentSection==="my_notes" && this.state.myNotes.data[0].id!=='') {
      content = this.renderNotesBoxes(this.handleNoteModal,this.state.myNotes,this.openIndividualNote)
    }
    return (
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
        <HamburgerMenu {...this.props} open={this.state.open} handleMenu={this.handleMenu}  themeDark={this.state.darkTheme} handleTheme={this.handleTheme}/>
        <MainContainer data-test-id="mainContainer" style={{paddingLeft: this.state.open ? 190 : 50}}>
          <AddNoteModal open={this.state.openNoteModal} onClose={this.closeNoteModal}>
            <ModalPaper>
            <AddNoteBox>
              <EachNoteBox style={{padding:"34px",height:"24px",borderBottom:"1px solid #D7D7D7"}}>
              <InputField data-test-id="addTitleInput" defaultValue="Add Note" onChange={this.handleNoteTitle} value={this.state.title}></InputField>
              </EachNoteBox>
              <EachNoteBox style={{flexDirection:"column",height:"auto"}}>
              <FormTextArea minRows={3} data-test-id="addNoteInput" defaultValue="Add Note here" onChange={this.handleNoteDescription} value={this.state.note}></FormTextArea>
              {
                (this.state.imagePreview) ? 
                (<ImageBox>
                  <img src={this.state.imagePreview} data-test-id="imageTest"
                  style={{ width: "760px",
                  height: "198px",
                  gap: "0px",
                  borderRadius: "18px",
                  opacity: "0px"}}></img>
                </ImageBox>) : <div style={{ width: "760px",
                              height: "198px",
                              gap: "0px",
                              borderRadius: "18px",
                              opacity: "0px"}}></div>
              }
              {
                (this.state.videoPreview) ? 
                (<ImageBox>
                  <video src={this.state.videoPreview}
                  style={{ width: "760px",
                  height: "198px",
                  gap: "0px",
                  borderRadius: "18px",
                  opacity: "0px"}}/>
                </ImageBox>) : <div style={{ width: "760px",
                              height: "198px",
                              gap: "0px",
                              borderRadius: "18px",
                              opacity: "0px"}}></div>
              }
              </EachNoteBox>
              <EachNoteBox style={{height:"64px"}}>
              <ModalIconBox style={{borderBottom:"1px solid #D7D7D7"}}>
                <Box style={{width:"164px",height:"64px",display:"flex",gap:"10px"}}>
                <img src={attachIcon} style={{width:"24px",height:"24px"}} onClick={this.handleDialogModal}/>
                <img src={atIcon} style={{width:"24px",height:"24px"}} onClick={this.handleDialogModal}/>
                <img src={cloudIcon} style={{width:"24px",height:"24px"}} onClick={this.handleDialogModal}/>
                </Box>
              </ModalIconBox>
              </EachNoteBox>
              <EachButtonNoteBox style={{height:"44px"}}>
              { this.state.enableEdit ? <AddNoteButton data-test-id="editPostTest" style={{width:"70px",height:"44px"}} onClick={this.handleEditProjectNote}>Save</AddNoteButton>
              : <AddNoteButton data-test-id="addPostTest" style={{width:"70px",height:"44px"}} onClick={this.handlePostProjectNote}>Save</AddNoteButton>}
              </EachButtonNoteBox>
              <DropdownMenu open={this.state.openDialog} onClose={this.closeDialogModal}>
                <MenuItem><MenuTypo>select</MenuTypo></MenuItem>
                <MenuItem>
                <label htmlFor="myInput" className="profile-upload-btn" data-test-id="profile-upload-btn">
                        <input
                          data-test-id="updateImage"
                          id="myInput"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={this.uploadMedia}
                        />
                  <img src={cloudIcon} style={{width:"16px",height:"16px"}}/><MenuTypo>image</MenuTypo>
                  </label>
                  </MenuItem>
                <MenuItem>
                <label htmlFor="myVideo" className="profile-upload-btn" data-test-id="profile-upload-btn">
                        <input
                          data-test-id="updateVideo"
                          id="myVideo"
                          type="file"
                          accept="video/*"
                          style={{ display: "none" }}
                          onChange={this.uploadMedia}
                        /><img src={videoIcon} style={{width:"16px",height:"16px"}}/><MenuTypo>video</MenuTypo>
                </label>        
                </MenuItem>
                <MenuItem>
                <label htmlFor="myDoc" className="profile-upload-btn" data-test-id="profile-upload-btn">
                        <input
                          data-test-id="uploadDoc"
                          id="myDoc"
                          type="file"
                          accept=".pdf,.doc,.docx,.txt,.xls,.xlsx"
                          style={{ display: "none" }}
                          onChange={this.uploadMedia}
                        />
                  <img src={attachIcon} style={{width:"16px",height:"16px"}}/><MenuTypo>Documents</MenuTypo>
                </label>
                </MenuItem>
              </DropdownMenu>
            </AddNoteBox>
            </ModalPaper>
          </AddNoteModal>
            <NavBar>
              <Grid container spacing={4}>
                <NavGrid item md={4} xs={4}>
                  <SearchBar
                    variant="outlined"
                    placeholder="Search"
                   InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{color:"#8C8C8C"}}/>
                      </InputAdornment>
                    ),
                  }}/>
                </NavGrid>
                <NavGrid item md={4} lg={4}>
                  <NavLinkBox>
                    <NavLinkActiveTypography>Dashboard</NavLinkActiveTypography>
                    <NavLinkTypography>Courses</NavLinkTypography>
                    <NavLinkTypography>Community</NavLinkTypography>
                  </NavLinkBox>
                </NavGrid>
                <NavGrid item>
                  <ProfileBox>
                    <BadgeContainer badgeContent={0} color="secondary">
                      <img src={mailIcon} style={{width:"24px",height:"24px"}}/>
                    </BadgeContainer>
                    <BadgeContainer badgeContent={0} color="secondary">
                      <img src={bellIcon}  style={{width:"24px",height:"24px"}}/>
                    </BadgeContainer>
                    <ProfileImageNameBox>
                      <img src={profileImage} style={{width:"40px",height:"40px"}}/>
                      <NameBox>
                        <NameTypography>Yash M.</NameTypography>
                        <PositionTypography>Worker</PositionTypography>
                      </NameBox> 
                      <ArrowDropDownRounded style={{color:"#1F1F1F"}}/> 
                    </ProfileImageNameBox>
                  </ProfileBox>
                </NavGrid>
              </Grid>
            </NavBar>
            { this.renderHeaderNoteBar(this.handleNoteModal,this.state.allNotes,this.state.myNotes,this.handleSectionChange,this.state.currentSection)}
            { content }
          </MainContainer>
      </ThemeProvider>
    );
  }
}
const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: theme.palette.background.default,
  height: 'inherit',
  '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main
  }
}))
const NavBar = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "75px",
  padding: "15.79px 36.45px 15.79px 33.5px",
  borderRadius: "18px",
  border:"1px",
  background: theme.palette.background.paper,
  margin:"48px 26px",
}))
const NotesHeaderBox = styled(Box)(({ theme }) => ({
  width: "1072px",
  height: "49px",
  padding: "10.32px 36.52px 10.32px 33.56px",
  borderRadius: "18px",
  border: "1px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center"
}))
const EmptyNotesBox = styled(Box)(({ theme }) => ({
  width: "316px",
  height: "187px",
  gap: "32px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center"
}))
const IndividualNoteBox = styled(Box)(({ theme }) => ({
  width: "1099px",
  height: "537px",
  padding: "12px 12px 12px 16px",
  gap: "8px",
  borderRadius: "6px 0px 0px 0px",
  border: "0px 0px 0px 4px",
  opacity: "0px",
  backgroundColor: theme.palette.background.default,
  borderLeft: "4px solid #1A469C",
  display:"flex",
  flexDirection:"column",
  alignItems:"flex-start",
  justifyContent:"flex-start"
}))
const MoreOptionsMenu = styled(Menu)(({ theme }) => ({
  width: "300px",
  height: "96px",
  gap: "0px",
  borderRadius: "18px",
  opacity: "0px",
  border: "1px solid #FFFFFF",
  boxShadow: "0px 2px 4px 0px #00000026",
  backgroundColor: theme.palette.background.default,
  top:"33% !important",
  left:"72% !important",
  "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded":{
    maxWidth: "300px",
    maxHeight: "96px",
    width: "300px",
    height: "96px",
    top: "0px !important",
    left: "0px !important",
    transition: "none",
    transformOrigin: "none",
    boxShadow: " 0px 2px 4px 0px #00000026 !important",
    backgroundColor: `${theme.palette.background.default} !important`,
    borderBottomLeftRadius: "18px",
    borderBottomRightRadius: "18px",
  }
}))

const ImageBox = styled(Box)(({ theme }) => ({
  width: "760px",
  height: "68px",
  gap: "0px",
  borderRadius: "18px",
  opacity: "0px",
}))
const AddNoteModal = styled(Modal)(({ theme }) => ({
  width: "832px",
  height: "480px",
  padding: "35px 34px 35px 34px",
  gap: "10px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
  borderRadius:"18px",
  top:"25% !important",
  left:"22% !important",
}))
const ImageModal = styled(Modal)(({ theme }) => ({
  width: "832px",
  height: "480px",
  padding: "35px 34px 35px 34px",
  gap: "10px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
  borderRadius:"18px",
  top:"25% !important",
  left:"22% !important",
}))
const ModalPaper = styled(Paper)(({ theme }) => ({
  width: "832px",
  height: "auto",
  padding: "35px 34px 35px 34px",
  gap: "10px",
  opacity: "0px",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
  borderRadius:"18px",
}))
const DropdownDialog = styled(Dialog)(({ theme }) => ({
  width: "220px",
  height: "184px",
  gap: "0px",
  borderRadiusBottomLeft: "18px",
  borderRadiusBottomRight: "18px",
  opacity: "0px",
  boxShadow: "0px 2px 4px 0px #00000026",
  border: "1px solid #FFFFFF",
  backgroundColor: "#FFFFFF"
}))
const DropdownMenu = styled(Menu)(({ theme }) => ({
  width: "220px",
  height: "184px",
  gap: "0px",
  borderBottomLeftRadius: "18px",
  borderBottomRightRadius: "18px",
  opacity: "0px",
  boxShadow: "0px 2px 4px 0px #00000026",
  border: `1px solid ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.background.default,
  top:"72% !important",
  left:"25% !important",
  "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded":{
    maxWidth: "220px",
    maxHeight: "200px",
    width: "220px",
    height: "180px",
    top: "0px !important",
    left: "0px !important",
    transition: "none",
    transformOrigin: "none",
    boxShadow: " 0px 2px 4px 0px #00000026 !important",
    backgroundColor: "#FFF !important",
    borderBottomLeftRadius: "18px",
    borderBottomRightRadius: "18px",
  }
}))
const MainNoteBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "500px",
  gap: "32px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center"
}))
const GroupBoxes = styled(Box)(({theme})=>({
  width:"1070px",
  height:"612px",
  gap:"0px",
  borderRadius:"18px",
  backgroundColor:theme.palette.background.paper,
  display:"flex",
  flexDirection:"row",
  alignItems:"self-start",
  justifyContent:"left",
  padding:"31px 37px",
  margin:"10px",
}))
const AllNotesBoxHeader = styled(Box)(({ theme }) => ({
  width: "1078px",
  height: "44px",
  gap: "17px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"space-between"
}))
const BoxHeaderLink = styled(Box)(({ theme }) => ({
  width: "285px",
  height: "32px",
  gap: "12px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center"
}))
const HeaderLinks = styled(Box)(({ theme }) => ({
  width: "168px",
  height: "32px",
  padding: "8px 0px 0px 0px",
  gap: "10px",
  borderRadius: "8px 8px",
  border: "2px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center"
}))
const AddButton = styled(Button)(({ theme }) => ({
  width: "183px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: "1px solid #1A469C",
  textTransform:"capitalize",
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#1A469C"
  }
}))
const EachNoteBox = styled(Box)(({ theme }) => ({
  width: "745.44px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"left",
  justifyContent:"left"
}))
const NoteBox = styled(Box)(({ theme }) => ({
  width: "326px",
  top: "231px",
  left: "355px",
  padding: "9px 18px 9px 18px",
  gap: "10px",
  borderTopLeftRadius: "6px",
  borderBottomLeftRadius: "6px",
  opacity: "0px",
  backgroundColor: theme.palette.background.default,
  borderLeft: "4px solid #1A469C"
}))
const NoteTitleBox =styled(Box)(({ theme }) => ({
  width: "290px",
  height: "28px",
  gap: "0px",
  justifyContent: "space-evenly",
  alignItems:"center",
  opacity: "0px",
  display: "flex",
  flexDirection: "row"
}))
const IndNoteTitleBox =styled(Box)(({ theme }) => ({
  width: "1059px",
  height: "36.17px",
  gap: "0px",
  justifyContent: "space-between",
  opacity: "0px",
  alignItems:"center",
  display: "flex",
  flexDirection: "row"
}))
const ImageHeadingBox = styled(Box)(({ theme }) => ({
  width: "637px",
  height: "38px",
  gap: "16px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"left",
  justifyContent:"left"
}))
const HeadingBox = styled(Box)(({ theme }) => ({
  width: "207px", height: "38px", gap: "0px", opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"left",
  justifyContent:"left"
}))
const MenuTypo = styled(Typography)(({ theme }) => ({
  width: "40px",
  height: "22px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.action.disabled
}))
const EditTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  width: "67px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  color: "#1A469C"
}))
const DeleteTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  width: "86px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  color: "#DC2626"
}))
const HeadingTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "32px",
  fontWeight: 500,
  lineHeight: "37.92px",
  textAlign: "center",
  color: "#0F172A",
}))
const NoteTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left",
  width: "197px",
  height: "22px",
  gap: "0px",
  opacity: "0px",
  color: theme.palette.text.hint
}))
const NoteDescription = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14px",
  textAlign: "left",
  width: "290px",
  gap: "0px",
  opacity: "0px",
  color: theme.palette.text.hint,
  margin:"10px",
}))
const IndNoteDescription = styled(Typography)(({ theme }) => ({
  width: "725.91px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "14px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const SubHeadingBox = styled(Box)(({ theme }) => ({
  width: "366px",
  height: "26px",
  gap: "23px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"left",
  justifyContent:"left"
}))
const SubHeadingTypo = styled(Typography)(({ theme }) => ({
  width: "133px",
  height: "26px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "26.07px",
  textAlign: "left",
  color: "#334155"
}))
const EachButtonNoteBox = styled(Box)(({ theme }) => ({
  width: "745.44px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"right",
  justifyContent:"right"
}))
const AddNoteBox = styled(Box)(({ theme }) => ({
  width: "764px",
  height: "407px",
  padding: "0px 2px 0px 2px",
  gap: "23px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"flex-start"
}))
const AddImageBox = styled(Box)(({ theme }) => ({
  width: "764px",
  height: "407px",
  padding: "0px 2px 0px 2px",
  gap: "23px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"flex-start"
}))
const InputField = styled('input')(({ theme }) => ({
  width: "100%",
  height: "24px",
  border:"none",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.text.hint,
  backgroundColor:theme.palette.background.default,
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #D7D7D7'
  },
  '& .MuiInput-underline:after': {
      borderBottom: '1px solid #D7D7D7'
  }
}))
const FormTextArea=styled(TextareaAutosize)(({theme})=>({
  width: "745px",
  height: "22px",
  gap: "0px",
  opacity: "0px",
  border:"none",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.text.hint,
  resize:"none",
  backgroundColor:theme.palette.background.default
}))
const NotesTitleBox = styled(Box)(({ theme }) => ({
  width: "285px",
  height: "32px",
  gap: "12px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center"
}))
const ModalIconBox = styled(Box)(({ theme }) => ({
  width: "745px",
  height: "64px",
  padding: "10px 0px 0px 0px",
  gap: "10px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"flex-start",
  borderBottom:"1px solid #D7D7D7",
}))
const Title = styled(Box)(({ theme }) => ({
  width: "85px",
  height: "32px",
  padding: "8px 0px 0px 0px",
  gap: "10px",
  borderRadius: "8px 8px",
  border: "2px",
  opacity: "0px"
}))

const NoNoteHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "center",
  color: "#0F172A",
  width: "90px", 
  height: "26px",
}))
const NoNoteDetailsTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "center",
  color: "#334155",
  width: "260px",
  height: "52px",
  gap: "0px",
  opacity: "0px"
}))
const AddNoteButton = styled(Button)(({ theme }) => ({
  width: "316px",
  height: "45px",
  padding: "16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  background: theme.palette.info.contrastText,
  textTransform:"capitalize",
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    width: "110px",
    height: "22px",
    gap: "0px",
    opacity: "0px",
    color: "#F8FAFC"
  },
  "&:hover": {
    background: theme.palette.info.contrastText,  
    color: "#F8FAFC"
  }
}))
const TitleTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: "#475569"
}))
const TitleActiveTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: "#1A469C"
}))
const NavGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}))
const SearchBar = styled(TextField)(({ theme }) => ({
  width: '257px',
  height: '44px',
  padding: '10px 16px',
  gap: '8px',
  borderRadius: '98px',
  border: `1px solid ${theme.palette.info.dark}`,
  backgroundColor: theme.palette.background.default,
  '& .MuiInputBase-root': {
    padding: 0,
    height: '100%',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '8px',
  },
}))
const NavLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifyContent: "center"
}))
const NavLinkTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.text.secondary,
  "& .active":{
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    padding: "8px",
    gap: "10px",
    borderRadius: "0px 0px 8px 8px"
  }
}))
const NavLinkActiveTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.action.selected,
  borderBottom: `2px solid ${theme.palette.action.selected}`,
  padding: "8px",
  gap: "10px",
  borderRadius: "0px 0px 8px 8px"
}))
const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex", flexDirection: "row", gap: "8px"
}))
const ProfileImageNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px"
}))
const BadgeContainer = styled(Badge)(({ theme }) => ({
  width: "44px",
  height: "44px",
  padding: "10px",
  gap: "4px",
  borderRadius: "8px",
  opacity: "0px",
}))
const NameBox = styled(Box)(({ theme }) => ({

}))
const NameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.main
}))
const PositionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.light,
  textTransform:"capitalize",
}))
const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
      primary: {
          main: '#FFFFFF',
          light:"010101"
      },
      secondary: {
          main: '#FFFFFF',
          light:"#D1FAE5",
          dark:"#0F172A"
      },
      background: {
          default: '#FFFFFF',
          paper: 'rgba(238,238,238,1)'
      },
      text: {
          primary: '#334155',
          secondary: '#475569',
          hint: '#0F172A',
      },
      action: {
          active: '#F1F5F9',
          selected: '#1A469C',
          focus: '#059669',
          disabled: '#475569'
      },
      info: {
          main: "#21272A",
          light: '#848484',
          dark: '#CBD5E1',
          contrastText:'#1A469C'
      },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
      primary: {
          main: '#161616',
          light:"#BDCFF4"
      },
      secondary: {
          main: '#2F2F2F',
          light:"#074525",
          dark:"#BDCFF4"
      },
      background: {
          default: '#0D0D0D',
          paper: 'rgba(26,26,26,1)',
      },
      text: {
          primary: '#D5DDF0',
          secondary: '#93A1B5',
          hint: '#D5DDF0'
      },
      action: {
          active: '#70A1FF',
          selected: '#70A1FF',
          focus: '#42EBB5',
          disabled: '#3C3E49'
      },
      info: {
          main: "#D5DDF0",
          light: '#99A7BB',
          dark: '#273444',
          contrastText:'#12326F'
      },
  },
  typography: FontStyle
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

// Customizable Area End
