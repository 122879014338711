import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Typography,
  Toolbar,
  InputBase,
  IconButton,
  Avatar,
  List,
  ListItemText,
  ListItemIcon,
  Chip as MuiChip,
  styled,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { lightTheme, darkTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { funnel, searchAgain, search, delete_warning, bg, lightMail, lightBell, darkBell, darkMail } from "./assets";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  AdvancedFilters = () => (
    <div data-test-id="advanced-filters" style={{ zIndex: 100 }}>
      <Dropdown>
        <div>
          <Header>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextColor variant="subtitle1" style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                <img src={funnel} alt="funnel" style={{ marginRight: 8 }} />
                <div onClick={this.handleFilters} data-test-id="filters-button">Filters</div>
              </TextColor>
              <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
              <TextColor
                variant="subtitle1"
                style={{ marginLeft: 8, fontSize: "12px" }}
              >
                <div onClick={this.handleHistory} data-test-id="search-history-button">Search History</div>
              </TextColor>
            </div>
            <Divider />
            <StyledIconButton size="small" >
              <CloseIcon fontSize="small" style={{ color: "#1A469C" }} onClick={this.handleClose} data-test-id="close-button" />
            </StyledIconButton>
          </Header>
          
          <Divider />
          {this.state.isLoading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
              <CircularProgress data-test-id="loading-spinner" />
            </div>
          ) : (
            <List data-test-id="search-history-list" style={{ maxHeight: "250px", overflowY: "auto" }}>
              {this.state.searchHistory.map((item, index) => (
                <div key={index} onClick={()=>this.handleAddToSearchHistory(item.id)} data-test-id="addSearch">
                  <div style={{ display: "flex", alignItems: "center" }} data-test-id={`search-history-item-${index}`}>
                    <ListItemIcon style={{ minWidth: 0 }}>
                      {index < 2 ? <img src={searchAgain} alt="searchAgain" /> : <img src={search} alt="search" />}
                    </ListItemIcon>
                    <ListItemText style={{ display: "flex", marginRight: "15px" }}
                      primary={
                        <div style={{ display: "flex", flexDirection: "column", marginRight: "10px", alignItems: "baseline" }}>
                          <Typography variant="body2" component="span" style={{ fontSize: "8px" }}>
                            Course Name
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8 }}
                          >
                            {item.courseName}
                          </Typography>
                        </div>
                      }
                      secondary={
                        item.groupName && (
                          <div style={{ display: "flex", alignItems: "baseline" }}>
                            <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <Typography variant="body2" component="span" style={{ fontSize: "8px" }}>
                                Group Name
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8 }}
                              >
                                {item.groupName}
                              </Typography>
                            </div>
                          </div>
                        )
                      }
                    />
                  </div>
                  <div>
                    {index < this.state.searchHistory.length - 1 && <Divider />}
                  </div>
                </div>
              ))}
            </List>
          )}
          <SeeAllResults onClick={() => this.goToFilters("Filteritems")} data-test-id="see-all-results-button">
            <TextColor variant="body2">See all results</TextColor>
            <ChevronRightIcon fontSize="small" style={{ color: "#1A469C" }} />
          </SeeAllResults>
        </div>
      </Dropdown>
    </div>
  );

  SaveFilters = () => (
    <div style={{ position: "absolute", top: "90px", left: "60px", zIndex: 100 }} data-test-id="save-filters">
      <Dropdown data-test-id="dropdown">
        <div data-test-id="dropdown-content">
          <Header data-test-id="header">
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextColor variant="subtitle1" style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                <img src={funnel} alt="funnel" style={{ marginRight: 8 }} />
                <div onClick={this.handleFilters} data-test-id="filters-button">Filters</div>
              </TextColor>
              <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
              <TextColor variant="subtitle1" style={{ marginLeft: 8, fontSize: "12px" }}>
                <div onClick={this.handleHistory} data-test-id="history-button">Search History</div>
              </TextColor>
            </div>
            <Divider />
            {this.state.selectedFilters.length !== 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextColor variant="subtitle1" style={{ marginLeft: 8, color: "#DC2626", marginRight: 8, fontSize: "12px" }}>
                  <div onClick={this.handleReset} data-test-id="reset-filters-button">Reset Filters</div>
                </TextColor>
                <StyledIconButton size="small" data-test-id="close-button">
                  <CloseIcon fontSize="small" style={{ color: "#1A469C" }} onClick={this.handleClose} />
                </StyledIconButton>
              </div>
            )}
          </Header>
          <Divider />
          <List style={{ maxHeight: "250px", overflowY: "auto" }}>
            {this.state.searchHistory.map((item, index) => (
              <div data-test-id={`search-history-item-${index}`} key={index} onClick={()=>this.handleAddToSearchHistory(item.id)}>
                <div style={{ padding: "5px 0", display: "flex", alignItems: "center",  }}>
                  <ListItemIcon style={{ minWidth: 0 }}>
                    {index > 2 ? <img src={search} alt="search" /> : <img src={searchAgain} alt="searchAgain" />}
                  </ListItemIcon>
                  <div style={{ display: "flex", width: "100%", marginRight: "15px", }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                      <div style={{ width: "60%" , display: "flex", justifyContent: "space-between"}}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
                          <Typography style={{ fontSize: "8px" }} variant="body2" component="span">
                            Course Name
                          </Typography>
                          <Typography
                            style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8 }}
                            variant="body2"
                            component="span"
                          >
                            {item.courseName}
                          </Typography>
                        </div>
                        <Divider flexItem orientation="vertical"  style={{ marginLeft: 8, marginRight: 8 }} />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline", }}>
                          {item.groupName && (
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                              <div style={{ display: "flex" }}>
                                <div>
                                  <div style={{ marginRight: 10 , display: "flex", flexDirection: "column", alignItems: "baseline", }}>
                                    <Typography component="span" variant="body2"  style={{ fontSize: "8px" }}>
                                      Group Name
                                    </Typography>
                                    <Typography
                                      style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8 }}
                                      variant="body2"
                                      component="span"
                                    >
                                      {item.groupName}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
                        <Inprogress>{item.status === "in_progress" ? "INPROGRESS" : "COMPLETED"}</Inprogress>
                      </div>
                      <Typography style={{ fontSize: "8px" }}>{item.sort}</Typography>
                    </div>

                  </div>
                </div>
                <div>
                  {index < this.state.searchHistory.length - 1 && <Divider />}
                </div>
              </div>
            ))}
          </List>
          <SeeAllResults onClick={() => this.goToFilters("Filteritems")} data-test-id="see-all-results">
            <TextColor variant="body2">See all results</TextColor>
            <ChevronRightIcon fontSize="small" style={{ color: "#1A469C" }} />
          </SeeAllResults>
        </div>
      </Dropdown>
    </div>
  );

  renderFilters = () => (
    <div style={{ position: "absolute", top: "90px", left: "60px", zIndex: 100 }} data-test-id="filters-container">
      <Dropdown1 data-test-id="dropdown1">
        <Header data-test-id="filters-header">
          <ChevronLeftIcon
            fontSize="small"
            style={{ color: "#1A469C" }}
            onClick={this.handleFiltersBack}
            data-test-id="back-button"
          />
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }} data-test-id="filter-title">
            Filter by
          </Typography>
          <TextColor
            variant="subtitle1"
            style={{ marginLeft: 8, fontSize: "12px" }}
            onClick={this.handleHistory}
            data-test-id="all-filters-button"
          >
            All Filters
          </TextColor>
        </Header>
        <div>
          <Wrapper data-test-id="course-wrapper">
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }} data-test-id="course-title">
              Course
            </Typography>
            <div data-test-id="course-filters">
              {this.state.allFilters.course.map((filter, index) => (
                <StyledChip
                  data-test-id="course-chip"
                  key={index}
                  label={filter}
                  className={this.state.selectedFilters.includes(filter) ? "selected" : ""}
                  onClick={() => {this.handleChipClick(filter);this.handleSelectedFilters("cource",filter)}}
                  onDelete={
                    this.state.selectedFilters.includes(filter)
                      ? () => {this.handleRemoveFilter("course", filter);this.handleSelectedFilters("cource",filter)}
                      : undefined
                  }
                />
              ))}
            </div>
          </Wrapper>
          <Wrapper data-test-id="status-wrapper">
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }} data-test-id="status-title">
              Status
            </Typography>
            <div data-test-id="status-filters">
              {this.state.allFilters.status.map((filter, index) => (
                <StyledChip
                  key={index}
                  label={filter}
                  onClick={() => {this.handleChipClick(filter);;this.handleSelectedFilters("status",filter)}}
                  className={this.state.selectedFilters.includes(filter) ? "selected" : ""}
                  onDelete={() => {this.handleRemoveFilter("status", filter);this.handleSelectedFilters("status",filter)}}
                  deleteIcon={<CloseIcon />}
                  data-test-id="status-chip"
                />
              ))}
            </div>
          </Wrapper>
          <Wrapper data-test-id="sortby-wrapper">
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }} data-test-id="sortby-title">
              Sort by
            </Typography>
            <div data-test-id="sortby-filters">
              {this.state.allFilters.sort.map((filter, index) => (
                <StyledChip
                  key={index}
                  label={filter}
                  onClick={() => {this.handleChipClick(filter);this.handleSelectedFilters("sort",filter)}}
                  className={this.state.selectedFilters.includes(filter) ? "selected" : ""}
                  onDelete={() => {this.handleRemoveFilter("sort", filter);this.handleSelectedFilters("sort",filter)}}
                  deleteIcon={<CloseIcon />}
                  data-test-id="sortby-chip"
                />
              ))}
            </div>
          </Wrapper>
        </div>

        <Footer data-test-id="filters-footer">
          <BackButton onClick={this.handleFiltersBack} data-test-id="footer-back-button">Back</BackButton>
          <SaveButton onClick={this.handleSaveFilters} data-test-id="footer-save-button">Save Filters</SaveButton>
        </Footer>

      </Dropdown1>
    </div>
  )

  renderEmpty = () => (
    <Dropdown2 style={{ height: "300px", zIndex: 100 }} data-test-id="empty-dropdown">
      <Header data-test-id="empty-header">
        <ChevronLeftIcon
          fontSize="small"
          style={{ color: "#1A469C" }}
          onClick={this.handleClose}
          data-test-id="empty-back-button"
        />
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }} data-test-id="empty-title">
          Search History
        </Typography>
        <StyledIconButton size="small" data-test-id="empty-close-button">
          <CloseIcon
            fontSize="small"
            style={{ color: "#1A469C" }}
            onClick={this.handleClose}
            data-test-id="empty-close-icon"
          />
        </StyledIconButton>
      </Header>
      <Content data-test-id="empty-content">
        <Typography style={{ fontSize: "30px", fontWeight: "bold" }} data-test-id="empty-no-search">
          No Search Found
        </Typography>
        <Typography style={{ fontSize: "13px" }} data-test-id="empty-explore-text">
          Start exploring and your searches will appear here!
        </Typography>
      </Content>
      <Footer data-test-id="empty-footer">
        <BackButton
          onClick={this.handleClose}
          style={{ width: "100%" }}
          data-test-id="empty-back-button-footer"
        >
          Back
        </BackButton>
      </Footer>
    </Dropdown2>
  )

  renderHistory = () => (
    <div style={{ position: "relative", zIndex: 100 }} data-test-id="search-history">
      <Dropdown2 data-test-id="history-dropdown">
        <Header data-test-id="history-header">
          <ChevronLeftIcon
            fontSize="small"
            style={{ color: "#1A469C" }}
            onClick={this.handleFiltersBack}
            data-test-id="history-back-button"
          />
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }} data-test-id="history-title">
            Search History
          </Typography>
          <StyledIconButton size="small" data-test-id="history-close-button">
            <CloseIcon
              fontSize="small"
              style={{ color: "#1A469C" }}
              onClick={this.handleFiltersBack}
              data-test-id="history-close-icon"
            />
          </StyledIconButton>
        </Header>
        <div data-test-id="history-content">
          {this.state.isLoading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }} data-test-id="history-loading">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <List data-test-id="history-list" style={{ maxHeight: "250px", overflowY: "auto" }}>
                {this.state.searchAllHistory.map((item, index) => (
                  <div key={index} data-test-id={`history-item-${index}`}>
                    <section style={{ display: "flex", alignItems: "center", padding: "5px 0" }}>
                      <ListItemIcon style={{ minWidth: 0 }} data-test-id="history-item-icon">
                        {index < 2 ? <img src={searchAgain} alt="searchAgain" /> : <img src={search} alt="search" />}
                      </ListItemIcon>
                      <section style={{ display: "flex", marginRight: "15px", width: "100%" }}>
                        <section style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                          <section style={{ display: "flex", justifyContent: "space-between", width: "60%" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
                              <Typography variant="body2" component="span" style={{ fontSize: "8px" }}>
                                Course Name
                              </Typography>
                              <Typography
                                variant="body2"
                                component="span"
                                style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8 }}
                                data-test-id="CoursenameTest"
                              >
                                {item.courseName}
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
                            <section style={{ display: "flex", flexDirection: "column", alignItems: "baseline", }}>
                              {item.groupName && (
                                <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                  <section style={{ display: "flex" }}>
                                    <section>
                                      <section style={{ display: "flex", flexDirection: "column", alignItems: "baseline", marginRight: 10 }}>
                                        <Typography variant="body2" component="span" style={{ fontSize: "8px" }}>
                                          Group Name
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8 }}
                                        >
                                          {item.groupName}
                                        </Typography>
                                      </section>
                                    </section>
                                  </section>
                                </section>
                              )}
                            </section>
                            <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
                            <Inprogress>{item.status === "in_progress" ? "INPROGRESS" : "COMPLETED"}</Inprogress>
                          </section>
                          <Typography style={{ fontSize: "8px" }}>{item.sort}</Typography>
                        </section>
                      </section>
                    </section>
                    <div>
                      {index < this.state.searchAllHistory.length - 1 && <Divider data-test-id="history-divider" />}
                    </div>
                  </div>
                ))}
              </List>
            </div>
          )}
        </div>
        <Footer data-test-id="history-footer">
          <BackButton onClick={this.handleFiltersBack} data-test-id="history-back-button-footer">Back</BackButton>
          <SaveButton onClick={this.handleModalOpen} data-test-id="history-delete-all-button">Delete All</SaveButton>
        </Footer>
      </Dropdown2>

      {this.state.modalOpen &&
        <Popup data-test-id="history-popup">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} data-test-id="popup-header">
            <div style={{ width: "50%" }}>
              <Typography style={{ fontWeight: "bold" }} data-test-id="popup-warning-title">Delete warning</Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", width: "50%", justifyContent: "space-between" }} data-test-id="popup-divider">
              <Divider style={{ width: "auto" }} />
              <Typography id="alert-dialog-title" style={{ fontSize: "13px" }} data-test-id="popup-title">Search History</Typography>
              <Divider style={{ width: "auto" }} />
            </div>
          </div>

          <TextColor style={{ display: "flex", alignItems: "center", padding: 0, margin: "10px 0" }} data-test-id="popup-warning-text">
            <img src={delete_warning} alt="delete_warning" style={{ marginRight: 8 }} />
            <Typography style={{ fontSize: "10px", fontWeight: "bold" }}>Are you sure you want to delete all search history? This action cannot be undone.</Typography>
          </TextColor>

          <Footer data-test-id="popup-footer">
            <BackButton onClick={this.handleModalClose} data-test-id="popup-cancel-button">
              Cancel
            </BackButton>
            <SaveButton onClick={this.handleDelete} data-test-id="popup-delete-button">
              Delete
            </SaveButton>
          </Footer>
        </Popup>
      }
    </div>
  )

  renderHistory1 = () => (
    <div style={{ position: "absolute", top: "90px", left: "60px", zIndex: 100 }} data-test-id="history1-container">
      {this.state.searchAllHistory.length === 0 && this.state.isLoading === false ? (
        this.renderEmpty()
      ) : (
        this.renderHistory()
      )}
    </div>
  )

  renderFilters1 = () => (
    <div style={{ position: "absolute", top: "90px", left: "60px", zIndex: 100 }} data-test-id="filters1-container">
      {this.state.searchHistory.length === 0 && this.state.isLoading === false ? (
        this.renderEmpty()
      ) : (
        this.AdvancedFilters()
      )}
    </div>
  )

  renderHeader = () => (
    <div style={{ width: "100%" }} data-test-id="header-container">
      <Box data-test-id="appbar-box">
        <Appbar style={{ borderRadius: "15px" }} data-test-id="appbar">
          <Toolbar style={{ display: "flex", justifyContent: "space-around" }} data-test-id="toolbar">
            <Search data-test-id="search-container">
              <SearchIconWrapper data-test-id="search-icon-wrapper">
                <SearchIcon data-test-id="search-icon" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={(event) => this.handleInputChange(event)}
                data-test-id="search-input"
              />
            </Search>
            <BoxContainer style={{ display: "flex", fontSize: "16px" }} data-test-id="box-container">
              <TextColor1
                noWrap
                style={{ display: "block", marginRight: "16px" }}
                data-test-id="dashboard-link"
              >
                Dashboard
              </TextColor1>
              <TextColor1
                noWrap
                style={{ display: "block", marginRight: "16px" }}
                data-test-id="courses-link"
              >
                Courses
              </TextColor1>
              <TextColor1
                noWrap
                style={{ display: "block", marginRight: "16px" }}
                data-test-id="community-link"
              >
                Community
              </TextColor1>
            </BoxContainer>
            <Box style={{ display: "flex", alignItems: "center" }} data-test-id="notification-icons">
              <img
                style={{ width: "20px", height: "20px", marginRight: "15px" }}
                src={this.state.darkMode ? darkMail : lightMail}
                alt="mail-icon"
                data-test-id="mail-icon"
              />
              <img
                style={{ width: "25px", height: "25px" }}
                src={this.state.darkMode ? darkBell : lightBell}
                alt="notification-icon"
                data-test-id="notification-icon"
              />
              <Box style={{ display: "flex", alignItems: "center" }} data-test-id="user-info">
                <Avatar
                  alt="Ibrahim M."
                  src="https://your-image-url.com"
                  style={{ width: 35, height: 35, marginRight: 8, marginLeft: 15 }}
                  data-test-id="user-avatar"
                />
                <Box data-test-id="user-details">
                  <Typography variant="body2" style={{ color: "black", fontWeight: "bold" }} data-test-id="user-name">
                    {this.state.profileData.attributes.preferred_name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" data-test-id="user-role">
                    {this.state.profileData.attributes.role_id}
                  </Typography>
                </Box>
                <IconButton style={{ color: "black" }} data-test-id="dropdown-button">
                  <ArrowDropDownIcon data-test-id="dropdown-icon" />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </Appbar>
      </Box>
    </div>
  )
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.darkMode ? darkTheme : lightTheme} data-test-id="theme-provider">
        <ContainerBody data-test-id="container-body">
          {this.renderHeader()}
          {this.state.history && this.renderHistory1()}
          {this.state.isOpen && this.renderFilters1()}
          {this.state.filters && this.renderFilters()}
          {this.state.openSaveFilters && this.SaveFilters()}
        </ContainerBody>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledChip = styled(MuiChip)(({ theme }) => ({
  marginLeft: "10px",
  textTransform:"capitalize",
  "&.selected": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main,
  },
  "&:not(.selected)": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.action.selected,
  },
  "& .MuiChip-deleteIcon": {
    display: "none",
  },
  "&.selected .MuiChip-deleteIcon": {
    display: "block",
    color: theme.palette.primary.main,
  },
}));


const TextColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.selected
}))

const Inprogress = styled(Typography)(({ theme }) => ({
  backgroundColor: '#FEF3C7',
  color: '#D97706',
  border: 'none',
  borderRadius: '30px',
  padding: '5px 10px',
  fontSize: '9px',
  fontWeight: 'bold',
}))


const TextColor1 = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light
}))

const ContainerBody = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  background: theme.palette.background.default,
}));

const StyledIconButton = styled(IconButton)({
  padding: 10,
});

const BoxContainer = styled(Box)({
  color: theme.palette.info.light,
})

const SearchIconWrapper = styled("div")({
  padding: "0 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "gray"
});

const Search = styled('div')(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: theme.palette.background.default,
  "&:hover": {
    border: `2px solid ${theme.palette.action.selected}`,
  },
  marginRight: "16px",
  marginLeft: 0,
  width: "100%",
  "@media (min-width:600px)": {
    marginLeft: "24px",
    width: "auto",
  },
  color: "black",
  border: "1px solid #CBD5E1",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  paddingLeft: "50px",
  '&::placeholder': {
    color: theme.palette.primary.main
  },
}));

const SaveButton = styled(IconButton)({
  background: "#1A469C",
  border: "none",
  color: "white",
  borderRadius: "30px",
  padding: "6px 10px 6px 10px",
  cursor: "pointer",
  width: "70%",
  height: "32px",
  fontSize: "16px",
  fontWeight: "bold",
});

const BackButton = styled(IconButton)({
  color: "#1A469C",
  borderRadius: "30px",
  padding: "6px 10px 6px 10px",
  cursor: "pointer",
  border: "1px solid #1A469C",
  width: "89px",
  height: "32px",
  fontSize: "16px",
  marginRight: "10px",
  fontWeight: "bold",
});

const Dropdown = styled(Paper)(({ theme }) => ({
  width: 500,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
}));

const Appbar = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const Dropdown1 = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 500,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
}));

const Dropdown2 = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 500,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
}));

const Content = styled('div')(({ theme }) => ({
  backgroundImage: `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 50px top 10px',
  textAlign: "center",
  padding: "50px"
}));

const Popup = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 500,
  top: 100,
  left: 50,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(3),
}));

const Header = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}));

const Footer = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "15px 0"
}));

const SeeAllResults = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: theme.spacing(1),
  color: theme.palette.primary.main,
  cursor: "pointer",
}));
// Customizable Area End
