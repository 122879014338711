export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logOutImage = require("../assets/logout.png");
export const arrowDown = require("../assets/chevron-down.png");
export const cross = require("../assets/cross.png");
export const averageIon = require("../assets/averageIon.svg");
export const certificateIcon = require("../assets/certificateIcon.svg");
export const completedIcon = require("../assets/completedIcon.svg");
export const progressIcon = require("../assets/progressIcon.svg");
export const watchIcon = require("../assets/watchIcon.svg");
export const calenderIcon = require("../assets/calender.svg");
export const exploreIcon = require("../assets/explore.svg");
export const Skip = require("../assets/Skip.svg");
export const buttonIcon = require("../assets/button.svg");
